import {getAxios} from "./AuthActions";
import {store} from "../store/ConfigureStore";
import {API, BY_DAY_ABSENCE, BY_HOUR_ABSENCE, JUSTIFY_HOUR} from "../api/Endpoints";
import {showTimedToaster} from "./ReferencesActions";
import moment from "moment";
import { strings } from "../i18n/Strings"; 

export function setShowAddJustification(payload) {

    return {
        type: "SET_SHOW_ADD_JUSTIFICATION",
        payload
    }

}

export function getAbsenceByDaySuccess(payload) {

    return {
        type: "GET_ABSENCE_BY_DAY",
        payload
    }

}

export function getAbsenceByHourSuccess(payload) {

    return {
        type: "GET_ABSENCE_BY_HOUR",
        payload
    }

}

export const getAbsenceByDay = (page, size, justified, registrationNumber, startDate, endDate) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.dayAbsence;
        dispatch(getAbsenceByDaySuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_DAY_ABSENCE}`, {
            "matricule": registrationNumber,
            "nom": null,
            "prenom": null,
            "startDate": startDate,
            "endDate": endDate,
            "justified": justified,
            "currentUser": store.getState().auth.profile.userName,
          "authorities": [],
          "order": true,
          "sort": "registrationNumber",
          "page": page,
            "size": size,
        });
        dispatch(getAbsenceByDaySuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));

    }
};


export const getAbsenceByHour = (page, size, justified, registrationNumber, startDate, endDate, active) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.hourAbsence;
        const $startDate = (startDate === "") ? null : startDate;
        const $endDate = (endDate === "") ? null : endDate;
        dispatch(getAbsenceByHourSuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_HOUR_ABSENCE}`, {
                "matricule": registrationNumber,
                "active": !!active || active === undefined,
                "nom": null,
                "prenom": null,
                "dateAbsence": null,
                "startDate": $startDate,
                "endDate": $endDate,
                "startHour": null,
                "endHour": null,
                "justified": justified,
                "order": true,
                "sort": "registrationNumber",
                "currentUser": store.getState().auth.profile.userName,
                "authorities": ["ROLE_RH_CONGE"],
                "page": page,
                "size": size
            }
        );
        dispatch(getAbsenceByHourSuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));
    }
};

function reverseDate(date) {
    date = date.replace(/-/g,"/");
    let temp = date.split("T");
    return `${temp[0].split("/").reverse().join("/")} ${temp[1]}`
}

export const getAbsenceLessThanHour = (startDate, endDate, matricule) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.hourAbsence;
        const $startDate = (startDate === "") ? null : startDate;
        const $endDate = (endDate === "") ? null : endDate;
        dispatch(getAbsenceByHourSuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/conges/abscence/minuteAbsence`, {startDate: moment($startDate).format("DD/MM/YYYY"),endDate: moment($endDate).format("DD/MM/YYYY"), matricule});
        dispatch(getAbsenceByHourSuccess({
            loading: false,
            data: absences.data.payload.map( abs => {
                let {daily,end, idMyshift,  justified, matricule, nom, prenom, start} = abs;
                return {daily,end: reverseDate(end), idMyshift,  justified, matricule, nom, prenom, start: reverseDate(start), updateDate: reverseDate(start).split(" ")[1]};
            }),
            totalElements: absences.data.totalElements
        }));
    }
};



export const justifyAbsence = (id, idMyshift, matricule, nom, prenom, start, end, justifType, justified, daily) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respJustifyAbsence} = strings; 

        await axios.post(`${API}/${JUSTIFY_HOUR}`, [{
                "id": id,
                "idMyshift": idMyshift,
                "matricule": matricule,
                "nom": nom,
                "prenom": prenom,
                "start": start,
                "end": end,
                "justifType": justifType,
                "justified": justified,
                "daily": daily
            }]
        );
        dispatch(getAbsenceByHour(2,25));
        dispatch(showTimedToaster({open: true, message: _respJustifyAbsence, type: "success"}));

    }
};
