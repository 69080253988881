export const GET_ERRORS = "GET_ERRORS";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const GET_PROJECT = "GET_PROJECT";
export const GET_BACKUP = "GET_TEAMS_BACKUP";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const GET_USERS = "GET_USERS";
export const GET_PLANNING = "GET_PLANNING";
export const GET_EXTERNAL_PLANNING = "GET_EXTERNAL_PLANNING";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SELECT_MEMBERS = "SELECT_MEMBERS";
export const GET_PLANNINGS_DASH = "GET_PLANNINGS_DASH";
