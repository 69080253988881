import React, {useCallback, useEffect, useRef, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import * as MdIcons from "react-icons/md";
import Avatar from "@material-ui/core/Avatar";
import Grow from "@material-ui/core/Grow";
import {connect , useDispatch} from "react-redux";
import Paper from '@material-ui/core/Paper';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import './list.css';
import {getAvailability, selectMembers} from "../actions/planningActions";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const DataList = ({
  title,
  level,
  data,
  icon,
  handleShowUsers,
  setShowUserDetails,
  setUser,
  handleShowEquipes,
  handleBackFromUsers,
  handleBackToTeams,
  setShowScheduler,
  setShowLoader,
  setEquipes,
  equipes,
  setSites,
  sites,
  currentTeam,
  setSite,
  handleShowSites,
  setCurrentTeam,
  team,
  getPlannings,
  setListSelectedAgents,
  setListSelectedEquipes,
  user,
  dispatch,
  availabilities,
  date,
  listSelectedAgents,
  listObjectsSelectedAgents,
  setListObjectsSelectedAgents,
  setDate
}) =>
{
  console.log("DATA = ", data);
  console.log("TITLE = ", title);

  let checkRef = useRef([]);
  let checkAllRef = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [avail,setAvail] = useState();
  //const dispatch = useDispatch();
  const listSelectedEquipes = [];

  console.log("check listSelectedAgents : ",listSelectedAgents);

  console.log("AVAILLLL = ", avail);
  useEffect(() => {
    checkAllRef.current = React.createRef();
    checkRef.current = data.map(
        (ref, index) =>   checkRef.current[index] = React.createRef()
    )
    console.log("created refs : ",checkRef);
  },[data]);

  useEffect(() => {
    if(level === "site"){
      setDate(new Date());
    }
  },[level]);

  useEffect(() => {
    clearListAgents();
    console.log("cleared agents : ",listSelectedAgents);
  },[date]);

  useEffect(() => {
    console.log("date in useeffect: ",date);
    console.log("list equipes : ",equipes);
    availabilities && console.log("list availabilities 1 : ",availabilities);

    let selectedUser1 = null
    if(level === "membres" && equipes){
      console.log("availibility team : ",equipes.employeeVOS);
      let listUsersForAvailibityCheck = "";
      let listUsers = equipes.employeeVOS;
      if(listUsers.length > 0){
        for(let i = 0;i < listUsers.length;i++){
          listUsersForAvailibityCheck = listUsersForAvailibityCheck + listUsers[i].userName +",";
        }
      }
      let formatted_date = date.getFullYear() + "-" + String((date.getMonth() + 1)).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');//String(dateObj.getDate()).padStart(2, '0');
      console.log("formatted_date in useeffect: ",formatted_date);
      dispatch(getAvailability(listUsersForAvailibityCheck,formatted_date));
      setAvail(availabilities);
      setSelected(false);
      clearListAgents();
      console.log("setListSelectedAgents : ",listSelectedAgents);
    }
  }, [date]);

  const clearListAgents =  () => {
    console.log("call clear list agents");
    setListSelectedAgents([]);
    setListObjectsSelectedAgents([]);
    console.log("clear list agents called : ", listSelectedAgents);
  }

  useEffect(() => {
    if(level === "membres" && equipes){
      setSelectAll(false);
      console.log("checkAllRef.current.current",checkAllRef.current.current)
      if(checkAllRef.current.current !== null){
        checkAllRef.current.current.checked = false;
        checkAllRef.current.current.change = onSelectAll;
      }
      console.log("checkRef before : ",checkRef.current);
      console.log("checkRef before : availabilities ",availabilities);
      let i = 0;
      for(let item in availabilities){
        console.log("checkRef before set1 : ",checkRef.current[i]);
        if(checkRef.current[i] !== null){
          console.log("checkRef before set2 : ",checkRef.current[i].current);
          if(checkRef.current[i].current !== null) {
            checkRef.current[i].current.checked = false;
          }
        }
        i++;
      }

      for(let j=0;j<checkRef.current.length;j++){
        if(checkRef.current[j] !== null && checkRef.current[j] !== undefined){
          console.log("checkRef before set2 : ",checkRef.current[j].current);
          if(checkRef.current[j].current !== null && checkRef.current[j].current !== undefined) {
            checkRef.current[j].current.change = onChange;
          }
        }
      }
      listSelectedAgents = [];
      setListSelectedAgents(listSelectedAgents);
      console.log("checkRef after : ",checkRef.current);
    }
  }, [availabilities]);

  useEffect( () => {
    setAvail(availabilities);
    console.log("AVAILL USEEFFECT" , avail);
    console.log("AVAILLABILITIES USEEFFECT" , availabilities);
  }, [availabilities]);

  const handleClick = (e) => {
    console.log("Clicked title=", title);
    console.log("Clicked=", e);
    let selectedUser1 = null;
    let selectedSite = null;
    if(level === "equipe" ){
      selectedUser1= data.filter(
          (item) => item.teamName == e.target.textContent
      );
      handleShowUsers(selectedUser1);
      setEquipes(selectedUser1[0]);
      setCurrentTeam(selectedUser1[0]);

      console.log("availibility team : ", selectedUser1[0].employeeVOS);
      let listUsersForAvailibityCheck = "";
      let listUsers = selectedUser1[0].employeeVOS;
      if (listUsers.length > 0) {
        for (let i = 0; i < listUsers.length; i++) {
          listUsersForAvailibityCheck = listUsersForAvailibityCheck + listUsers[i].userName + ",";
        }
      }
      let formatted_date = date.getFullYear() + "-" + String((date.getMonth() + 1)).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');//String(dateObj.getDate()).padStart(2, '0');
      dispatch(getAvailability(listUsersForAvailibityCheck, formatted_date));
      setAvail(availabilities);
    }
    if(level === "site" ){
      console.log("data of sites : ",data);
      selectedSite= data.filter(
          (item) => item.siteName == e.target.textContent
      );
      console.log("handleClick show equipes selectedSite : ",selectedSite);
      handleShowEquipes(selectedSite);
      setSites(selectedSite[0]);
      setSite(selectedSite[0]);
    }
    //title === "Equipe(s)" && handleShowUsers(selectedUser1);
    //title === "Site(s)" && handleShowEquipes(selectedSite);
    //title === "Equipe(s)" && setShowLoader(true);
    if(level === "membres"){
      const selectedUser = data.filter(
          (item) => item.fullName+ " "+item.userName == e.target.textContent
      );
      setUser(selectedUser[0]);
      setShowUserDetails(true);
      setShowLoader(true);
      //setEquipes();
      setDate(new Date());
    }

    if(level === "search"){
      const selectedUser = data.filter(
          (item) => item.fullName+ " "+item.userName == e.target.textContent
      );
       setShowUserDetails(true);
       setShowLoader(true);
       setShowScheduler(true);
       //setEquipes();
       //setSites();
       setUser(selectedUser[0]) ;
       //setDate(new Date());
    }
    //title === "Membres de l’équipe" && setShowUserDetails(true);
    //title === "Membres de l’équipe" && setShowLoader(true);
    //title === "Membres de l’équipe" && setEquipes();
    const selectedUser = data.filter(
      (item) => item.fullName+ " "+item.userName == e.target.textContent
    );
    const selectedEquipes = data.filter(
      (item) => item.teamName == e.target.textContent
    );
    //title === "Membres de l’équipe" && setUser(selectedUser[0]) ;
    //title === "Users" &&  getPlannings(selectedUser[0].id);
    //title === "Equipe(s)" && setEquipes(selectedEquipes[0]);
    //title === "Site(s)" && setSites(selectedSite[0]);
    //title === "Site(s)" && setSite(selectedSite[0]);
    //title === "Equipe(s)" && setCurrentTeam(selectedUser1[0]);
    setOpen(!open);
  };

  const onChange = (args) => {
  let id = args.event.target.getAttribute('id');
  console.log("listSelectedAgents in check111111 : ",listSelectedAgents);
  console.log("id checkbox : ",id);
  console.log("args checkbox : ",args);
  let obj = {id:id,fullname:args.event.target.defaultValue};
  if (args.checked) {
     // console.log("listSelectedAgents in check : ",listSelectedAgents);
    listSelectedAgents.push(id);
    listObjectsSelectedAgents.push(obj);
    // console.log("checked ",id);
    // console.log("listSelectedAgents after checked ",listSelectedAgents);
    // setListSelectedAgents(listSelectedAgents);
  }
  else {
    for( let i = 0; i < listSelectedAgents.length; i++){
      if ( listSelectedAgents[i] === id) {
        listSelectedAgents.splice(i, 1);
        listObjectsSelectedAgents.splice(i,1)
        i--;
      }
    }
    // listSelectedAgents = listSelectedAgents.filter(e => e != id);
    // console.log("unchecked ",id);
    console.log("listObjectSelectedAgents after unchecked ",listObjectsSelectedAgents);
    // setListSelectedAgents(listSelectedAgents);
  }
    dispatch(selectMembers(listSelectedAgents))
    setListSelectedAgents(listSelectedAgents);
    setListObjectsSelectedAgents(listObjectsSelectedAgents);
}

  const checkDisabled = (userName) => {
    //console.log("checkiiiinngggg",availabilities);
    let res = false;
    if(availabilities) {
      //console.log("checkiiiinngggg 2",availabilities);
      for(let item in availabilities){
        //console.log("item available :",item);
        if(item === userName) {
          //console.log("itemmmmmmmm",item)
          //console.log("availabilities[item]",availabilities[item]);
          return availabilities[item];
        }
      }
    }
    return res;
  }
  const onSelectAll = (args) => {
    console.log("onselect all1 ",avail);
    console.log("onselect all2 ",availabilities);
    if(level === "membres"){
      if(args.checked){
        listSelectedAgents = [];
        listObjectsSelectedAgents=[];
        setSelected(true);
        /*data.map((item, index) => [
          setSelected(true),
          listSelectedAgents.push(item.userName)
        ])*/
        for(let key in availabilities){
          if(availabilities[key] === false){
            listSelectedAgents.push(key)
            const selectedItem = data.filter(item =>
              item.userName === key
            );
            let obj = {id:selectedItem[0].userName,fullname:selectedItem[0].fullName};
            listObjectsSelectedAgents.push(obj)
            console.log("selectedItem : ",selectedItem);
          }
        }
        console.log("list selected agents in select all : {0} and list availabilities : {1} ",listSelectedAgents,availabilities);
        setListObjectsSelectedAgents(listObjectsSelectedAgents);
      setListSelectedAgents(listSelectedAgents);
      }
      else{
        listSelectedAgents = [];
        listObjectsSelectedAgents = [];
        setListSelectedAgents(listSelectedAgents);
        setListObjectsSelectedAgents(listObjectsSelectedAgents);
        setSelected(false);
      }
    }
    dispatch(selectMembers(listSelectedAgents))

    console.log("listSelectedAgents pour selectionner toute l'equipe : ",listSelectedAgents);
  }

const onChangeEquipes = (args) => {
  let id = args.event.target.getAttribute('id');
  if (args.checked) {
    listSelectedEquipes.push(id);
    setListSelectedEquipes(listSelectedEquipes);
    console.log("listSelectedAgents checked ",listSelectedEquipes);

  }
  else {
    for( var i = 0; i < listSelectedEquipes.length; i++){
      if ( listSelectedEquipes[i] === id) {
        listSelectedEquipes.splice(i, 1);
        i--;
      }
    }
    console.log("listSelectedAgents after unchecked ",listSelectedEquipes);
    setListSelectedEquipes(listSelectedEquipes);
  }
}

  const handleBack = () => {
    setShowUserDetails(false);
    setShowScheduler(false);
    if(level === "equipe" || level === "membres"|| level === "search")
      handleShowSites();
    //title === "Membres de l’équipe" && handleShowSites();
    //level === "equipe" && handleShowSites();
    setUser();
    setEquipes();
  };

  console.log("TILTE = ", title);

  return (
    <Paper style={{maxHeight: 700, overflow: 'auto'}} elevation={0}>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          style={{ display: "flex", alignItems: "center",paddingRight:0 }}
          component="div"
          id="nested-list-subheader"
        >
          {level === "membres" && (
            <MdIcons.MdKeyboardBackspace
              onClick={user ? handleBackFromUsers : handleBackToTeams}
              style={{ fontSize: "1.5rem", margin: "0 0.5rem" }}
            />
          )}
          {level === "search" && (
            <MdIcons.MdKeyboardBackspace
              onClick={currentTeam? handleBackFromUsers : sites ? handleBackToTeams : handleBack}
              style={{ fontSize: "1.5rem", margin: "0 0.5rem" }}
            />
            )}
          {level === "equipe" && (
            <MdIcons.MdKeyboardBackspace
              onClick={handleBack}
              style={{ fontSize: "1.5rem", margin: "0 0.5rem" }}
            />
          )}
          <span className='list-header' style={{marginInlineEnd: "auto"}}>{title}</span>
          {
            level === "membres" && !user && (
              <CheckBoxComponent value="Select All" checked={selectAll} change={onSelectAll} ref={checkAllRef.current} />
          )
          }
        </ListSubheader>
      }
      className={classes.root}
    >
      {data.map((item, index) => (
        <Grow in={true} {...{ timeout: 1000 }}>
          <div style={{display:'flex'}}>
          <ListItem key={index} button onClick={handleClick} alignItems="flex-start">
            <ListItemAvatar style={{ pointerEvents: "none" }}>
              {level === "equipe" ?
            <span className="e-badge e-badge-warning e-badge-circle">{item.assignmentKind}</span>
                          : level === "membres" || level === "search" ?
              <Avatar
                style={{ marginRight: "1rem", pointerEvents: "none" }}
                alt={item.fullName}
                src={"https://mscore.intelcia.com/picture/default/"+item.userName}
              /> : icon
                      }
                    </ListItemAvatar>
                    {level === "membres" && [
              <ListItemText primary={item.fullName + " " + item.userName }

              />
            ]}
            {level === "search" && [
              <ListItemText primary={item.fullName + " " + item.userName }

              />
            ]}
            {level === "membres" && checkDisabled(item.userName) &&(
                <ListItemIcon style={{ pointerEvents: "none" }}>
                  <span className="e-badge e-badge-success e-badge-pill">Planifié</span>
                </ListItemIcon>
            )}
            {level === "membres" && !checkDisabled(item.userName) &&(
              <ListItemIcon style={{ pointerEvents: "none" }}>
              <span className="e-badge e-badge-danger e-badge-pill">Non Planifié</span>
              </ListItemIcon>
              )}
            {level === "equipe" &&
            (
              <ListItemText primary={item.teamName}
              />
              )}
              {level === "site" &&
              (
              <ListItemText primary={item.siteName}
              />
              )}
          </ListItem>
            {level === "membres" && !user && (
                <CheckBoxComponent key={item.userName} index={item.userName} value={item.fullName} id={item.userName} checked={checkDisabled(item.userName) ? false : selected} change={onChange} disabled={checkDisabled(item.userName)} ref={checkRef.current[index]} />
            )}

          </div>
        </Grow>
      ))}
    </List>
    </Paper>
  );
};
const mapStateToProps = state => ({
  errors: state.errors,
  plannings:state.project.plannings,
  availabilities:state.project.availabilities
});



export default connect(mapStateToProps)(DataList);
