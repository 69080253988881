const initialState = {
    profile: {
        "userName": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "avatar": "",
        "sectret2FA": null,
        "qrCode": null,
        "authorities": [],
        "userApps": []
    },
    isLoggedIn: false,
    searchActive: false,
    loading: false,
    authoritiesConge: [],
    roleMan: false,
    language: {}
};

export default function authReducer(state = initialState, {type, payload}) {
    switch (type) {
        case "GET_LANGUAGE":
            return {...state, language: payload};
        case "GET_PROFILE_SUCCESS":
            return {...state, profile: payload};
        case "GET_AUTHORITIES_CONGE":
            return {...state, authoritiesConge: payload};
        case "IS_LOGGED_IN":
            return {...state, isLoggedIn: payload};
        case "SEARCH_ACTIVE":
            return {...state, searchActive: payload};
        case "IS_LOADING":
            return {...state, loading: payload};
        case "IS_ROLE_MAN":
            return {...state, roleMan: payload};
        default:
            return state
    }
}

