const initialRequestState = {loading: false, data: [], details: {}, totalElements: 0};

export default function absenceReducer(state = {
    showAddJustification: {open: false, details: {}},
    dayAbsence: {
        loading: false,
        details: {},
        totalElements: 0,
        data: []
    },
    hourAbsence: {
        loading: false,
        data: [],
        details: {},
        totalElements: 0
    },
}, {type, payload}) {
    switch (type) {
        case "GET_ABSENCE_BY_DAY":
            return {...state, dayAbsence: payload};
        case "GET_ABSENCE_BY_HOUR":
            return {...state, hourAbsence: payload};
        case "SET_SHOW_ADD_JUSTIFICATION":
            return {...state, showAddJustification: payload};
        default:
            return state
    }
}


