import LocalizedStrings from "react-localization";

export const language =
  window.navigator.userLanguage || window.navigator.language;

export function hasLanguage() {
  return language == "fr-FR" ? "DD/MM/YYYY" : "YYYY/MM/DD";
}

export const stringsLangs = {
  fr: {
    _lang: "fr",
    _toJustify: "A justifer",
    _firstName: "Prénom",
    _lastName: "Nom",
    _registrationNumber: "Matricule",
    _absenceDay: "Date de l'absence",
    _absenceStartHour: "Heure début",
    _absenceEndHour: "Heure fin",
    _justified: "Justifiée",
    _added: "Ajoutée",
    _deleted: "Supprimée",
    _canceled: "Annulée",
    _minor: "Mineur",
    _normal: "Normale",
    _major: "Majeur",
    _enabled: "Activé",
    _disabled: "Désactivé",
    _payedHoliday: "Congé payé",
    _unpayedHoliday: "Congé sans solde",
    _approved: "Acceptée",
    _pending: "En cours",
    _rejected: "Refusée",
    _cancelationRequest: "Demande d'annulation",
    _absenceRequest: "Demande de congé",
    _recupStatusConsumed: "Etat de consommation de la récupération",
    _loading: "Chargement",
    _rejectionMotif: "Motif de refus",
    _exceptionalHolidayType: "Type de congé exceptionnel",
    _home: "Accueil",
    _updateDate: "Date de modification",
    _recuperation: "Récupération",
    _requestStatus: "Statut de la demande",
    _teleoptiMapping: "Mapping teleopti",
    _exceptionalHoliday: "Congé Social",
    _exceptionalAbsence: "Absence Exceptionnelle",
    _exceptionalHolidayFr: "Absence pour évènement exceptionnel",
    _exceptionalHolidays: "Congé Sociaux",
    _publicHoliday: "Jours férié",
    _rejectionMotifs: "Motifs de refus",
    _addedRequests: "Demandes Ajoutée",
    _processedRequests: "Demandes traitées",
    _pendingRequests: "Demandes en attente",
    _childrenRequests: "Congés de mes collaborateurs",
    _allPendingRequests: "Toutes demandes en attente",
    _payedCollaboratorsHolidays: "Congés de mes collaborateurs",
    _holidayCancelation: "Annulation Congès",
    _effectifsHolidays: "Congès des effectifs",
    _lastUpdate: "Dernière mise à jour du solde",
    _exceptionalLeave: "",
    _resulstsFound: "Résultats trouvés:",
    _csvExport: "Export CSV",
    _myUOHolidays: "Les congés de mon UO",
    _of: "sur",
    _publicHolidays: "Jours fériés",
    _myUO: "Mon UO",
    _annualBalance: "Solde annuel",
    _pendingBalance: "Nombre de jours en attente d'export 'Solde Annuel'",
    _cumulativeBalance: "Solde Cumulé",
    _from: "du",
    _to$: "au",
    _employeesBalance: "Soldes de mes collaborateurs",
    _noResultsFound: "Aucun resultat",
    _2faActivation: "Activation 2FA",
    _seeker: "Demandeur",
    _requestDate: "Date de  demande",
    _holidayType: "Type de congé",
    _startDate: "Date de début",
    _returnDate: "Date de retour",
    _finishDate: "Date de fin",
    _endDate: "Date de fin",
    _duration: "Durée",
    _totalDuration: "Durée totale",
    _consumedDuration: "Durée consommée",
    _partner: "Collaborateur",
    _validator: "Validateur",
    _backup: "Backup",
    _requestType: "Type de demande",
    _status: "Statut",
    _addedForEmp: "Créé pour",
    _label: "Libéllé",
    _provedByDocuments: "Pièces Justificative",
    _country: "Pays",
    _newHolidayRequest: "Nouvelle demande de congé",
    _cancel: "annuler",
    _confirm: "confirmer",
    _holidayDuration: "Durée du congé",
    _comment: "Commentaire",
    _follow: "Suivi",
    _history: "Historique",
    _absenceDuration: "Durée absence",
    _abbreviation: "Abréviation",
    _allCountries: "Tous les pays",
    _criticality: "Criticité",
    _holidayDeletion: "Suppression du congé",
    _assignedRole: "Role à assigner",
    _writeRecuperation: "Saisir une récupération",
    _writeSetting: "Saisir un paramétrage",
    _writeHoliday: "Saisir Congé récupération",
    _teleoptiHolidayType: "Type de congé Teleopti",
    _exceptionalHOlidayMapping: "Mapping des types de congés exceptionnels",
    _deletetion: "Suppression",
    _recupDeletetion: "Suppression du type de récupération",
    _mappingTeleoptiDeletionConfirmation:
      "Confirmez-vous la suppression du mapping Téléopti ?",
    _publicHolidayDeletionConfirmation:
      "Confirmez-vous la suppression du jour férié ?",
    _recupDeletionConfirmation:
      "Confirmez-vous la suppression de la récupération ?",
    _holidayDeletionConfirmation:
      "Confirmez-vous  la suppression du congé suivant :",
    _holidayCancelationConfirmation:
      "Confirmez-vous  l'annulation du congé suivant :",
    _commentDetails: "Saisir plus de details sur la raison de votre demande",
    _selectHolidayDuration: "Séléctionner durée de congé",
    _reasonOfRequest: "Raison de congé",
    _justificationOfAbsences: "Justification absences",
    _exportsManagement: "Gestion des exports",
    _disconnect: "Se déconnecter",
    _selectCountries: "Sélectionner les pays",
    _myRequests: "Mes demandes",
    _employee: "Employé",
    _dayAbsence: "Absence en jour",
    _hourAbsence: "Absence horaires",
    _holidayDetails: "Details de la demande",
    _typeRequest: "Type de la demande",
    _holidayPeriod: "Période du congé",
    _backupValidator: "Validateur backup",
    _INTERNAL_SERVER_ERROR:
      "Erreur interne du serveur, veuillez réessayer ultérieurement",
    _ADDED_FOR_EMPL_NULL: "Vous devez saisir un employé",
    _EMPTY_LEAVE: "Vous avez choisi un jour de congé qui coïncide avec un dayoff ou un jour férié.",
    _EMP_REQUIRED: `Merci de sélectionner l'employé correspondant `,
    _BACKUP_IS_NULL: "Le validateur backup n'a pas été choisi",
    _CANCEL_HOLIDAY_AFTER_START: "Vous ne pouvez pas annuler un congé après",
    _CANCEL_HOLIDAY_BY_OTHERS:
      "Vous ne pouvez annuler que les congés que vous avez créé",
    _CANCEL_HOLIDAY_CANCEL: "Voulez-vous annuler une demande d'annulation ?",
    _CANCEL_HOLIDAY_EXISTS:
      "Une demande d'annulation existe déjà pour cette demande",
    _CANCEL_HOLIDAY_IN_PROCESS: "Vous ne pouvez annuler que les congés validés",
    _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "Vous devez choisir un Pays",
    _COUNTRY_MUST_BE_SET: "Vous devez choisir un pays avant de continuer.",
    _COUNTRY_REJECTION_REASON: "Vous devez Choisir un pays",
    _CRITICALITY_REJECTION_REASON: "Vous devez choisir la criticité",
    _DATES_AFTER:
      "Les dates saisies doivent être antérieures à la date courante",
    _DATES_AFTER_DAY:
      "Règle RH : La date début du congès doit être supérieure à la date de demain",
    _DATES_AFTER_NOW:
      "Règle RH : La date début du congès doit être supérieure à la date de demain à la date d'aujourd'hui",
    _DATES_ARE_NULL: "Les dates n'ont pas été saisies",
    _DATES_IN_PERIOD: "Chevauchement de dates",
    _DATES_PAST:
      "Les dates saisies doivent être supérieures à la date courante",
    _DELETE_HOLIDAY_IN_PROCESS:
      "Vous ne pouvez supprimer que les congés non validés",
    _DELETE_REQUEST:
      "Vous ne pouvez pas supprimer un congé que vous n'avez pas créé",
    _DIFFERENT_QUOTA_TEAM:
      "Le site a des valeurs différentes pour les quotas équipe",
    _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Vous devez saisir une durée supérieure à 0",
    _EMPLOYEE_MUST_BE_SET: "Vous devez saisir un employé avant de continuer.",
    _EXCEPTIONAL_CONGE_TYPE_EXIST:
      "Le type de congé exceptionnel existe déja avec le même nom :",
    _EXCEPTIONAL_TYPE_NULL: "Vous devez choisir un type de congé exceptionnel",
    _INSUFFICIENT_SOLDE: "jours, Votre solde est insuffisant",
    _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "Vous devez saisir un label",
    _LABEL_REJECTION_REASON: "Vous devez saisir un label",
    _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Vous devez effectuer le mapping avec HR-Access",
    _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
      "Vous devez effectuer le mapping avec Teleopti",
    _MAPPING_EXIST_TYPE_TELEOPTI: "Ce mapping existe déjà avec Teleopti",
    _NO_BACKUP_VALIDATOR: "Vous devez spécifier un validateur backup pour:",
    _PUBLIC_HOLIDAY_EXISTS:
      "Il existe déjà un jour férié avec les mêmes dates pour le même pays",
    _PUBLIC_HOLIDAY_OVERLAP: "Il existe un chauvechement des jours fériés",
    _RECUP_DURATION_INSUFFICIENT:
      "Le congé de récupération que vous avez saisi dépasse la durée de la récupération qui est de :",
    _RECUP_EXISTS:
      "Il existe déjà une récupération avec les mêmes dates pour cet employé",
    _RECUP_MONTH:
      "Le congés de récupération ne peux pas être consomée après un mois",
    _RECUP_NOT_EXIST: "La récupération est inexistante",
    _RECUP_NOT_VALID: "La date saisie n'est ni un dayoff ni un jour férié",
    _RECUP_REQUEST_MONTH:
      "La récupération ne peux pas être ajoutée après un mois",
    _RECUP_REQUEST_NOT_VALID:
      "Les dates choisies ne sont pas des jours ouvrables",
    _REJECTION_REASON_EXIST: "Le motif de refus existe déja avec le même nom :",
    _REJECT_REQUEST_REASON: "Vous devez saisir un motif de refus",
    _REQUEST_DELETED: "Ce congé est déjà supprimé.",
    _REQUIRED_FIELDS:
      "Tous les champs doivent être remplis avant d'enregistrer la demande",
    _SAME_DATES_EXCEPTION: "Il existe déjà un congé avec les mêmes dates",
    _SCENARIO_MUST_BE_SET: "Vous devez saisir un scénario avant de continuer.",
    _SITE_NOT_FOUND: "Le site n'est pas configuré sur le référentiel",
    _SOLDE_ESTIMATED_INSUFFICIENT:
      "Votre solde estimé dépasse votre solde HR, vous ne pouvez pas soumettre des demandes de congés",
    _START_DATE_EXCEED_YEAR: "La date de début du congé dépasse une année",
    _STATE_MUST_BE_SET:
      "Vous devez choisir un avis avant de valider la demande.",
    _STATE_REJECTION_REASON: "Vous devez choisir un etat",
    _SYSTEM_REJECT_REQUEST: "Demande rejetée automatiquement par le système",
    _TEAM_NOT_FOUND: "L'équipe n'est pas configurée sur le référentiel",
    _TEAM_NOT_SET: "Equipe non paramétrée pour l'utilisateur",
    _TYPE_MUST_BE_SET: "Vous devez saisir un type de congé avant de continuer.",
    _UO_NOT_SET: "Site non paramétré pour l'utilisateur",
    _USER_NOT_EXIST: "L'employé est introuvable",
    _VALIDATOR_NOT_AVAILABLE:
      "Nous ne pouvons prendre en compte votre demande vu que celle-ci ne respecte pas le délai défini de J+2 ouvrés hors Jours Fériés ( Jours ouvrés = Du Lundi au Vendredi ). Nous vous remercions de changer les dates afin de respecter ce délai. Merci",

    _numbersOfDaysOff: "Nombre de DaysOff",
    _leaveDay: "Jour travaillé",
    _all: "Tous",
    _initializer: "Initiateur",
    _hr: "RH",
    _system: "Système",
    _ppv_system: "Système ( validation PPV automatique  )",
    _project: "Projet",
    _days: "Jour(s)",
    _avis: "Avis",
    _reasonOfRejection: "Motif de refus",
    _updatedBy: "Ajoutée par",
    _actif: "Collaborateur active",
    _unpaidSicknessHoliday: "Maladie Non Payée",
    _durationRecovery: "Durée récuperation",
    _comment: "Commentaire",
    _consumed: "Consommée",
    _expired: "Expirée",
    _active: "Active",
    _yes: "Oui",
    _no: "Non",
    _holidayDetailsMsg: "Cette demande n’est définitivement validée qu’après accord de votre hiérarchie",
    _requestDate: "Date de la demande",
    _return: "RETOUR",
    _cancelHoliday: "Annulation du congé",
    _removeHoliday: "Suppression du congé",
    _area: "Zone",
    _starting: "Départ",
    _project: "Projet",
    _end: "Fin",
    _team: "Equipe",
    _marageQuota: "Gestion des Quotas équipe",
    _geographicalArea: "Zone géographique",
    _searchQuota: "Recherche Quota",

    _respProcessRequest: "La demande a été traitée ",
    _respCancelRequest: "La demande a été annulée ",
    _respDeleteRequestOK: "La demande a été supprimée ",
    _respDeleteRequestKO: "Vous ne pouvez pas supprimer un congé en cours de validation ",
    _respDeleteRequestRecupOK: "La demande a été supprimée ",
    _respDeleteRequestRecupKO: "Vous ne pouvez pas supprimer cette Récupération ",
    _withoutPayHoliday: "Congé sans solde ",
    _edit: "modifier",
    _create: "créer",
    _withSuccess: "avec success ",
    _respDeleteRejectionReason: "Motif de refus supprimer avec success ",
    _respEditExceptionalHoliday:"Le congé social a été modifié avec success ", 
    _respAddExceptionalHoliday: "Le congé social a été ajouté avec success ",
    _respDeleteExceptionalHoliday: "Le congé social a été supprimé avec success ",
    _respAddPublicHoliday: "Le jours feries a été ajouté avec success ",
    _respEditPublicHoliday: "Le jours feries a été modifié avec success ",
    _respDeletePublicHoliday: "Le jours feries a été supprimé avec success ",
    _respSetQuota: "Le quota a été modifié avec success ",
    _respAddHolidayRequest: "votre demande a été ajoutée ", 
    _respInMassValidation: "validation en masse effectuée ", 
    _respCancelRequestRH: "Annulation du congé effectuée ", 
    _respAddHolidayRecup: "votre demande a été envoyée ", 
    _respFlagExportedOK: "La demande a été exportée ", 
    _respFlagExportedKO: "Erreur lors de l'export de la demande ", 
    _respExportToHrOk: "La demande a été exportée ", 
    _respExportToHrKO: "Erreur lors de l'export de la demande ", 
    _respJustifyAbsence: "Absence justifée avec success ", 
    _lastDay: "Last Day", 
    _unitDay: "Unit Day",
    _hraServer: "Instance HRACCESS",
    _validateur: "Validateur",
    _validationHorsCompEquipe: "Validation hors composition d'équipe",
    _rttBalance : "Solde RTT",
    _ancienneteBalance: "Solde d'ancienneté",
    _pendingRtt: "Nombre de jours en attente d'export 'RTT'",
    _pendingAnciennete: "Nombre de jours en attente d'export 'Ancienneté'",
    _rttHoliday: "Congé RTT",
    _ancienneteHoliday: "Congé Ancienneté",
    _am_label: "Après-midi",
    _pm_label: "Matinée"
  },
  en: {
    _respAddHolidayRequest: "votre demande a été ajoutée ", 
    _respInMassValidation: "validation en masse effectuée ", 
    _respCancelRequestRH: "Annulation du congé effectuée ", 
    _respAddHolidayRecup: "votre demande a été envoyée ", 
    _respFlagExportedOK: "La demande a été exportée ", 
    _respFlagExportedKO: "Erreur lors de l'export de la demande ", 
    _respExportToHrOk: "La demande a été exportée ", 
    _respExportToHrKO: "Erreur lors de l'export de la demande ", 
    _respJustifyAbsence: "Absence justifée avec success ", 
    _respSetQuota: "Le quota a été modifié avec success ",
    _edit: "modifier",
    _create: "créer",
    _withSuccess: "avec success ",
    _respDeleteRejectionReason: "Motif de refus supprimer avec success ",
    _respEditExceptionalHoliday:"Le congé social a été modifié avec success ", 
    _respAddExceptionalHoliday: "Le congé social a été ajouté avec success ",
    _respDeleteExceptionalHoliday: "Le congé social a été supprimé avec success ",
    _respAddPublicHoliday: "Le jours feries a été ajouté avec success ",
    _respEditPublicHoliday: "Le jours feries a été modifié avec success ",
    _respDeletePublicHoliday: "Le jours feries a été supprimé avec success ",
    _respProcessRequest: "La demande a été traitée ",
    _respCancelRequest: "La demande a été annulée ",
    _respDeleteRequestOK: "La demande a été supprimée ",
    _respDeleteRequestKO: "Vous ne pouvez pas supprimer un congé en cours de validation ",
    _respDeleteRequestRecupOK: "La demande a été supprimée ",
    _respDeleteRequestRecupKO: "Vous ne pouvez pas supprimer cette Récupération ",
    _withoutPayHoliday: "Congé sans solde ",

    _searchQuota: "Search Quota",
    _geographicalArea: "Geographical area",
    _marageQuota: "Team quotas management",
    _end: "Ending",
    _team: "Team",
    _area: "Area",
    _starting: "Starting",
    _project: "Project",
    _removeHoliday:"Deletion of leave",
    _cancelHoliday: "Cancellation of leave",
    _return: "RETURN",
    _requestDate: "Request date",
    _holidayDetailsMsg: "This request is definitively validated only after accord of your hierarchy",
    _yes: "Yes",
    _no: "No",
    _consumed: "Consumed",
    _expired: "Expired",
    _active: "Active",
    _comment: "Comment",
    _lang: "en",
    _toJustify: "To justify",
    _firstName: "Last name",
    _lastName: "First name",
    _registrationNumber: "Registration number",
    _absenceDay: "Absence day",
    _absenceStartHour: "Start hour",
    _absenceEndHour: "End hour",
    _justified: "Justified",
    _added: "Added",
    _deleted: "Deleted",
    _canceled: "Canceled",
    _minor: "Minor",
    _normal: "Normal",
    _major: "Major",
    _enabled: "Enabled",
    _disabled: "Disabled",
    _payedHoliday: "Payed holiday",
    _unpayedHoliday: "Unpayed holiday",
    _approved: "Approved",
    _pending: "Pending",
    _rejected: "Rejected",
    _cancelationRequest: "Cancelation request",
    _absenceRequest: "Absence Request",
    _recupStatusConsumed: "State of consumption of recovery",
    _loading: "Loading",
    _rejectionMotif: "Rejection motif",
    _exceptionalHolidayType: "Exceptional holiday type",
    _annualBalance: "Annual balance",
    _myUOHolidays: "Holiadys of my OU",
    _pendingBalance: "Balance requests pending",
    _updateDate: "Update date",
    _home: "Home",
    _recuperation: "Recovery",
    _requestStatus: "Request status",
    _teleoptiMapping: "Teleopti mapping",
    _exceptionalHoliday: "Exceptional leave",
    _exceptionalAbsence: "Exceptional Absence",
    _exceptionalHolidayFr: "Absence for exceptionnel holiday",
    _exceptionalHolidays: "Exceptional leave days",
    _publicHolidays: "Public holidays",
    _publicHoliday: "Public holiday",
    _rejectionMotifs: "Grounds for refusal",
    _addedRequests: "Requests added",
    _processedRequests: "Requests processed",
    _pendingRequests: "Requests pending",
    _cumulativeBalance: "Cumulative balance",
    _childrenRequests: "Leave of my colleagues",
    _allPendingRequests: "All requests pending",
    _payedCollaboratorsHolidays: "Payed requests ",
    _holidayCancelation: "Requests cancelation",
    _effectifsHolidays: "Effectifs holidays",
    _lastUpdate: "Last balance update",
    _exceptionalLeave: "",
    _resulstsFound: "Results found:",
    _csvExport: "CSV export",
    _of: "of",
    _myUO: "my OU",
    _from: "from",
    _to$: "to",
    _employeesBalance: "Balance of my collaborators",
    _noResultsFound: "No result",
    _2faActivation: " 2FA Activation",
    _seeker: "Seeker",
    _requestDate: "Request date",
    _holidayType: "Holiday Type",
    _startDate: "Start Date",
    _returnDate: "Return date",
    _finishDate: "End Date",
    _endDate: "End date",
    _duration: "Duration",
    _totalDuration: "Total duration",
    _consumedDuration: "Duration consumed",
    _partner: "Collaborator",
    _validator: "Validator",
    _backup: "Backup",
    _requestType: "Type of Request",
    _status: "status",
    _addedForEmp: "Created for",
    _label: "Label",
    _provedByDocuments: "Supporting Documents",
    _country: "Countries",
    _newHolidayRequest: "New holiday request",
    _cancel: "cancel",
    _confirm: "confirm",
    _holidayDuration: "Duration of leave",
    _comment: "Comment",
    _follow: "Follow",
    _history: "History",
    _absenceDuration: "Duration of absence",
    _abbreviation: "Abbreviation",
    _allCountries: "All countries",
    _criticality: "Criticality",
    _holidayDeletion: "Deletion of leave",
    _assignedRole: "Role to assign",
    _writeRecuperation: "Enter a recovery",
    _writeHoliday: "Enter a holiday recovery",
    _teleoptiHolidayType: "Leave type Teleopti",
    _exceptionalHOlidayMapping: "Mapping types of exceptional leave ",
    _deletetion: "Deletion",
    _recupDeletetion: "Deleting the type of recovery",
    _mappingTeleoptiDeletionConfirmation:
      "Do you confirm the removal of the Teleopti mapping?",
    _publicHolidayDeletionConfirmation:
      "Do you confirm the removal of the public holiday?",
    _recupDeletionConfirmation: "Do you confirm the removal of the recovery?",
    _holidayDeletionConfirmation:
      "Do you confirm the deletion of the following leave:",
    _holidayCancelationConfirmation:
      "Do you confirm the cancellation of the following leave:",
    _commentDetails: "type details about the reason of your requestDate départ",
    _dayAbsence: "Day absences",
    _hourAbsence: "Hour absences",
    _myRequests: "My requests",
    _justificationOfAbsences: "Absences justification ",
    _employee: "Employee",
    _typeRequest: "Request type",
    _backupValidator: "Backup validator",
    _holidayPeriod: "Holiday period",
    _addedRequest: "Your request has been added",
    _INTERNAL_SERVER_ERROR: "Internal Server Error, please try again later",
    _holidayDetails: "Holiday details",
    _RECUP_NOT_EXIST: "Recovery dos not exist",
    _RECUP_NOT_VALID:
      "The date entered is neither a Day Off nor a Public Holiday",
    _RECUP_REQUEST_NOT_VALID: "The dates choses are not available dates",
    _RECUP_REQUEST_MONTH: "The compensation cannot be added after one month",
    _RECUP_MONTH: "The compensation leave cannot be used after one month",
    _PUBLIC_HOLIDAY_EXISTS:
      "There is already a public holiday with the same dates for the same country",
    _PUBLIC_HOLIDAY_OVERLAP: "The public holidays overlap",
    _numbersOfDaysOff: "Numbers of DaysOff",
    _leaveDay: "Leave days",
    _all: "All",
    _initializer: "Initializer",
    _hr: "HR",
    _system: "System",
    _ppv_system: "System (  PPV validation automatic  )",
    _project: "Project",
    _days: "Days",
    _avis: "Decision",
    _reasonOfRejection: "Reason of rejection",
    _ADDED_FOR_EMPL_NULL: "You must enter a user",
    _EMPTY_LEAVE: "You must choose at lease one working day",
    _EMP_REQUIRED: `You must select an employee`,
    _BACKUP_IS_NULL: "The backup approval was not chosen",
    _CANCEL_HOLIDAY_AFTER_START: "You can not cancel a holiday after",
    _CANCEL_HOLIDAY_BY_OTHERS: "You can only cancel the holidays you created",
    _CANCEL_HOLIDAY_CANCEL: " Do you want to cancel a cancellation request?",
    _CANCEL_HOLIDAY_EXISTS:
      "A cancellation request already exists for this request",
    _CANCEL_HOLIDAY_IN_PROCESS: "You can only cancel approved holidays",
    _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "You must choose a Country",
    _COUNTRY_MUST_BE_SET: "You must choose a country before continuing.",
    _COUNTRY_REJECTION_REASON: "You must Choose a country",
    _CRITICALITY_REJECTION_REASON: "You must choose the criticality",
    _DATES_AFTER: "Dates entered must be prior to the current date",
    _DATES_AFTER_DAY:
      "HR Rule: The start date of the holiday must be after tomorrow's date",
    _DATES_AFTER_NOW:
      "HR Rule: The start date of the holiday must be after tomorrow's date of tomorrow today's date",
    _DATES_ARE_NULL: "The dates have not been entered",
    _DATES_IN_PERIOD: "Overlapping dates",
    _DATES_PAST: "The dates entered must be after the current date",
    _DELETE_HOLIDAY_IN_PROCESS:
      "You can only delete holidays that have not been approved",
    _DELETE_REQUEST: "You can not delete a vacation that you have not created",
    _DIFFERENT_QUOTA_TEAM: " The site has different values for team quotas",
    _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
      "You must enter a duration greater than 0",
    _EMPLOYEE_MUST_BE_SET: "You must enter an employee before continuing.",
    _EXCEPTIONAL_CONGE_TYPE_EXIST:
      "The type of holiday already exists with the same name:",
    _EXCEPTIONAL_TYPE_NULL: "You must choose a type of exceptional leave",
    _INSUFFICIENT_SOLDE: "days, Your balance is insufficient",
    _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "You must enter a label",
    _LABEL_REJECTION_REASON: "You must enter a label",
    _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
      "You must perform the mapping with HR-Access",
    _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
      "You must perform the mapping with Teleopti",
    _MAPPING_EXIST_TYPE_TELEOPTI: "This mapping already exists with Teleopti",
    _NO_BACKUP_VALIDATOR: "You must specify a backup validator for:",
    _RECUP_DURATION_INSUFFICIENT:
      "The compensation leave you entered exceeds the compensation time of:",
    _RECUP_EXISTS:
      "Compensation time with the same dates for this employee already exists",
    _REJECTION_REASON_EXIST:
      "The refusal reason already exists with the same name:",
    _REJECT_REQUEST_REASON: "You must enter a reason for refusal",
    _REQUEST_DELETED: "This holiday has already been deleted.",
    _REQUIRED_FIELDS:
      "All fields must be completed before saving the application",
    _SAME_DATES_EXCEPTION: "Leave already exists with these dates",
    _SCENARIO_MUST_BE_SET: "You must enter a scenario before proceeding.",
    _SITE_NOT_FOUND: "The site is not configured as a reference",
    _SOLDE_ESTIMATED_INSUFFICIENT:
      "Your estimated balance exceeds your HR balance, you cannot submit holiday requests",
    _START_DATE_EXCEED_YEAR:
      "The start date of the leave is in excess of one year",
    _STATE_MUST_BE_SET:
      "You must choose a decision before approving the request.",
    _STATE_REJECTION_REASON: "You must choose a state",
    _SYSTEM_REJECT_REQUEST: "Request automatically rejected by the system",
    _TEAM_NOT_FOUND: "The team is not configured as a reference",
    _TEAM_NOT_SET: "Team not set for the user",
    _TYPE_MUST_BE_SET: "You must enter a holiday type before continuing.",
    _UO_NOT_SET: "Site not set for the user",
    _USER_NOT_EXIST: "User does not exist",
    _VALIDATOR_NOT_AVAILABLE:
      "We cannot take into account your request as it does not within the defined deadline of D + 2 business days excluding Public Holidays (Business Days = From Monday to Friday). We thank you for changing the dates to meet this deadline. Thank you",
    _updatedBy: "Added by",
    _actif: "Actif partner",
    _unpaidSicknessHoliday: "unpaid sickness holiday",
    _disconnect: "Logout",
    _durationRecovery: "Duration recovery",
    _rttBalance : "RTT Balance",
    _ancienneteBalance: "Seniority Balance",
    _pendingRtt: "Validated days waiting for RTT export",
    _pendingAnciennete: "Validated days waiting for Seniority export",
    _rttHoliday: "RTT",
    _ancienneteHoliday: "Seniority",
    _lastDay: "Last Day", 
    _unitDay: "Unit Day",
    _hraServer: "Instance HRACCESS",
    _validateur: "Validateur",
    _validationHorsCompEquipe: "Validation hors composition d'équipe",
    _am_label: "Evening",
    _pm_label: "Morning"
  }


  
};
export let strings=stringsLangs.fr;
export function setLanguage(countryCode) {
    if(countryCode === "PT"){
        strings =stringsLangs.en;
        return stringsLangs.en;
    }else{
        strings =stringsLangs.fr;
        return stringsLangs.fr;
    }
}