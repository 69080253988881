export default class RefData {}

RefData.COUNTRY_CODES = {
  Ireland: "ie",
  Spain: "es",
  "United Kingdom": "gb",
  France: "fr",
  Germany: "de",
  Sweden: "se",
  Italy: "it",
  Greece: "gr",
  Iceland: "is",
  Portugal: "pt",
  Malta: "mt",
  Norway: "no",
  Brazil: "br",
  Argentina: "ar",
  Colombia: "co",
  Peru: "pe",
  Venezuela: "ve",
  Uruguay: "uy",
  Maroc: "ma",
  Madagascar: "mg",
  Sénégal: "sn",
  Cameroun: "cm",
  Maurice: "mu",
  Portugal: "pt",
  "Côte d'Ivoire": "ci",
  Tous: ""
};

export const activeCountries = [
  { id: 1, label: "Maroc" },
  { id: 2, label: "Senegal" },
  { id: 3, label: "Cameroun" },
  { id: 5, label: "France" },
  { id: 8, label: "Cote d'Ivoire" },
  { id: 140, label: "Madagascar" },
  { id: 148, label: "Maurice" },
  { id: 18, label: "Portugal"}
];

export const countries = [
  { id: 22, label: "Afghanistan" },
  { id: 206, label: "Afrique du Sud" },
  {
    id: 23,
    label: "Albanie"
  },
  { id: 12, label: "Algérie" },
  { id: 98, label: "Allemagne" },
  { id: 26, label: "Andorre" },
  {
    id: 27,
    label: "Angola"
  },
  { id: 192, label: "Anguilla" },
  { id: 24, label: "Antarctique" },
  {
    id: 28,
    label: "Antigua-et-Barbuda"
  },
  { id: 159, label: "Antilles Néerlandaises" },
  { id: 198, label: "Arabie Saoudite" },
  {
    id: 30,
    label: "Argentine"
  },
  { id: 36, label: "Arménie" },
  { id: 160, label: "Aruba" },
  { id: 31, label: "Australie" },
  {
    id: 32,
    label: "Autriche"
  },
  { id: 29, label: "Azerbaïdjan" },
  { id: 33, label: "Bahamas" },
  { id: 34, label: "Bahreïn" },
  {
    id: 35,
    label: "Bangladesh"
  },
  { id: 37, label: "Barbade" },
  { id: 53, label: "Bélarus" },
  { id: 6, label: "Belgique" },
  {
    id: 45,
    label: "Belize"
  },
  { id: 75, label: "Bénin" },
  { id: 38, label: "Bermudes" },
  { id: 39, label: "Bhoutan" },
  {
    id: 40,
    label: "Bolivie"
  },
  { id: 41, label: "Bosnie-Herzégovine" },
  { id: 42, label: "Botswana" },
  {
    id: 44,
    label: "Brésil"
  },
  { id: 49, label: "Brunéi Darussalam" },
  { id: 50, label: "Bulgarie" },
  {
    id: 235,
    label: "Burkina Faso"
  },
  { id: 52, label: "Burundi" },
  { id: 54, label: "Cambodge" },
  { id: 3, label: "Cameroun" },
  {
    id: 55,
    label: "Canada"
  },
  { id: 56, label: "Cap-vert" },
  { id: 61, label: "Chili" },
  { id: 62, label: "Chine" },
  {
    id: 73,
    label: "Chypre"
  },
  { id: 11, label: "Comores" },
  { id: 10, label: "Congo" },
  { id: 70, label: "Costa Rica" },
  {
    id: 8,
    label: "Côte d'Ivoire"
  },
  { id: 71, label: "Croatie" },
  { id: 72, label: "Cuba" },
  { id: 76, label: "Danemark" },
  {
    id: 94,
    label: "Djibouti"
  },
  { id: 77, label: "Dominique" },
  { id: 13, label: "Egypte" },
  { id: 80, label: "El Salvador" },
  {
    id: 222,
    label: "Émirats Arabes Unis"
  },
  { id: 79, label: "Équateur" },
  { id: 83, label: "Érythrée" },
  { id: 208, label: "Espagne" },
  {
    id: 84,
    label: "Estonie"
  },
  { id: 172, label: "États Fédérés de Micronésie" },
  { id: 233, label: "États-Unis" },
  {
    id: 82,
    label: "Éthiopie"
  },
  { id: 88, label: "Fidji" },
  { id: 89, label: "Finlande" },
  { id: 5, label: "France" },
  {
    id: 14,
    label: "Gabon"
  },
  { id: 96, label: "Gambie" },
  { id: 95, label: "Géorgie" },
  {
    id: 87,
    label: "Géorgie du Sud et les Îles Sandwich du Sud"
  },
  { id: 99, label: "Ghana" },
  { id: 100, label: "Gibraltar" },
  { id: 102, label: "Grèce" },
  {
    id: 104,
    label: "Grenade"
  },
  { id: 103, label: "Groenland" },
  { id: 105, label: "Guadeloupe" },
  { id: 106, label: "Guam" },
  {
    id: 107,
    label: "Guatemala"
  },
  { id: 15, label: "Guinée Bissau" },
  { id: 81, label: "Guinée Équatoriale" },
  {
    id: 183,
    label: "Guinée-Bissau"
  },
  { id: 108, label: "Guyana" },
  { id: 91, label: "Guyane Française" },
  {
    id: 109,
    label: "Haïti"
  },
  { id: 112, label: "Honduras" },
  { id: 113, label: "Hong-Kong" },
  { id: 114, label: "Hongrie" },
  {
    id: 43,
    label: "Île Bouvet"
  },
  { id: 64, label: "Île Christmas" },
  { id: 231, label: "Île de Man" },
  {
    id: 168,
    label: "Île Norfolk"
  },
  { id: 86, label: "Îles (malvinas) Falkland" },
  { id: 90, label: "Îles Aland" },
  {
    id: 57,
    label: "Îles Caïmanes"
  },
  { id: 65, label: "Îles Cocos (Keeling)" },
  { id: 69, label: "Îles Cook" },
  {
    id: 85,
    label: "Îles Féroé"
  },
  { id: 110, label: "Îles Heard et Mcdonald" },
  { id: 170, label: "Îles Mariannes du Nord" },
  {
    id: 173,
    label: "Îles Marshall"
  },
  { id: 171, label: "Îles Mineures Éloignées des États-Unis" },
  { id: 47, label: "Îles Salomon" },
  {
    id: 225,
    label: "Îles Turks et Caïques"
  },
  { id: 48, label: "Îles Vierges Britanniques" },
  {
    id: 234,
    label: "Îles Vierges des États-Unis"
  },
  { id: 116, label: "Inde" },
  { id: 117, label: "Indonésie" },
  { id: 16, label: "Irak" },
  {
    id: 7,
    label: "Irlande"
  },
  { id: 115, label: "Islande" },
  { id: 119, label: "Israël" },
  { id: 120, label: "Italie" },
  {
    id: 135,
    label: "Jamahiriya Arabe Libyenne"
  },
  { id: 121, label: "Jamaïque" },
  { id: 122, label: "Japon" },
  { id: 124, label: "Jordanie" },
  {
    id: 123,
    label: "Kazakhstan"
  },
  { id: 125, label: "Kenya" },
  { id: 129, label: "Kirghizistan" },
  {
    id: 101,
    label: "Kiribati"
  },
  { id: 128, label: "Koweït" },
  { id: 229, label: "L'ex-République Yougoslave de Macédoine" },
  {
    id: 132,
    label: "Lesotho"
  },
  { id: 133, label: "Lettonie" },
  { id: 131, label: "Liban" },
  { id: 134, label: "Libéria" },
  {
    id: 136,
    label: "Liechtenstein"
  },
  { id: 137, label: "Lituanie" },
  { id: 138, label: "Luxembourg" },
  { id: 139, label: "Macao" },
  {
    id: 140,
    label: "Madagascar"
  },
  { id: 142, label: "Malaisie" },
  { id: 141, label: "Malawi" },
  { id: 143, label: "Maldives" },
  {
    id: 144,
    label: "Mali"
  },
  { id: 145, label: "Malte" },
  { id: 1, label: "Maroc" },
  { id: 146, label: "Martinique" },
  {
    id: 148,
    label: "Maurice"
  },
  { id: 147, label: "Mauritanie" },
  { id: 66, label: "Mayotte" },
  { id: 149, label: "Mexique" },
  {
    id: 150,
    label: "Monaco"
  },
  { id: 151, label: "Mongolie" },
  { id: 153, label: "Montserrat" },
  {
    id: 154,
    label: "Mozambique"
  },
  { id: 51, label: "Myanmar" },
  { id: 155, label: "Namibie" },
  { id: 156, label: "Nauru" },
  {
    id: 157,
    label: "Népal"
  },
  { id: 164, label: "Nicaragua" },
  { id: 165, label: "Niger" },
  { id: 166, label: "Nigéria" },
  {
    id: 167,
    label: "Niué"
  },
  { id: 169, label: "Norvège" },
  { id: 161, label: "Nouvelle-Calédonie" },
  {
    id: 163,
    label: "Nouvelle-Zélande"
  },
  { id: 17, label: "Oman" },
  { id: 227, label: "Ouganda" },
  { id: 237, label: "Ouzbékistan" },
  {
    id: 175,
    label: "Pakistan"
  },
  { id: 174, label: "Palaos" },
  { id: 176, label: "Panama" },
  {
    id: 177,
    label: "Papouasie-Nouvelle-Guinée"
  },
  { id: 178, label: "Paraguay" },
  { id: 158, label: "Pays-Bas" },
  { id: 179, label: "Pérou" },
  {
    id: 180,
    label: "Philippines"
  },
  { id: 181, label: "Pitcairn" },
  { id: 182, label: "Pologne" },
  {
    id: 92,
    label: "Polynésie Française"
  },
  { id: 185, label: "Porto Rico" },
  { id: 18, label: "Portugal" },
  { id: 186, label: "Qatar" },
  {
    id: 215,
    label: "République Arabe Syrienne"
  },
  { id: 58, label: "République Centrafricaine" },
  { id: 127, label: "République de Corée" },
  {
    id: 152,
    label: "République de Moldova"
  },
  { id: 68, label: "République Démocratique du Congo" },
  {
    id: 130,
    label: "République Démocratique Populaire Lao"
  },
  { id: 78, label: "République Dominicaine" },
  { id: 67, label: "République du Congo" },
  {
    id: 118,
    label: "République Islamique d'Iran"
  },
  { id: 126, label: "République Populaire Démocratique de Corée" },
  {
    id: 74,
    label: "République Tchèque"
  },
  { id: 232, label: "République-Unie de Tanzanie" },
  { id: 187, label: "Réunion" },
  {
    id: 188,
    label: "Roumanie"
  },
  { id: 230, label: "Royaume-Uni" },
  { id: 19, label: "Russie" },
  { id: 189, label: "Rwanda" },
  {
    id: 191,
    label: "Saint-Kitts-et-Nevis"
  },
  { id: 196, label: "Saint-Marin" },
  { id: 194, label: "Saint-Pierre-et-Miquelon" },
  {
    id: 111,
    label: "Saint-Siège (état de la Cité du Vatican)"
  },
  { id: 195, label: "Saint-Vincent-et-les Grenadines" },
  { id: 190, label: "Sainte-Hélène" },
  {
    id: 193,
    label: "Sainte-Lucie"
  },
  { id: 240, label: "Samoa" },
  { id: 25, label: "Samoa Américaines" },
  {
    id: 197,
    label: "Sao Tomé-et-Principe"
  },
  { id: 2, label: "Sénégal" },
  { id: 242, label: "Serbie-et-Monténégro" },
  {
    id: 199,
    label: "Seychelles"
  },
  { id: 200, label: "Sierra Leone" },
  { id: 201, label: "Singapour" },
  {
    id: 202,
    label: "Slovaquie"
  },
  { id: 204, label: "Slovénie" },
  { id: 205, label: "Somalie" },
  { id: 209, label: "Soudan" },
  {
    id: 59,
    label: "Sri Lanka"
  },
  { id: 213, label: "Suède" },
  { id: 214, label: "Suisse" },
  { id: 210, label: "Suriname" },
  {
    id: 211,
    label: "Svalbard etÎle Jan Mayen"
  },
  { id: 212, label: "Swaziland" },
  { id: 216, label: "Tadjikistan" },
  { id: 63, label: "Taïwan" },
  {
    id: 60,
    label: "Tchad"
  },
  { id: 93, label: "Terres Australes Françaises" },
  {
    id: 46,
    label: "Territoire Britannique de l'Océan Indien"
  },
  { id: 97, label: "Territoire Palestinien Occupé" },
  { id: 217, label: "Thaïlande" },
  {
    id: 184,
    label: "Timor-Leste"
  },
  { id: 218, label: "Togo" },
  { id: 219, label: "Tokelau" },
  { id: 220, label: "Tonga" },
  {
    id: 221,
    label: "Trinité-et-Tobago"
  },
  { id: 21, label: "Tunisie" },
  { id: 224, label: "Turkménistan" },
  {
    id: 223,
    label: "Turquie"
  },
  { id: 226, label: "Tuvalu" },
  { id: 228, label: "Ukraine" },
  { id: 236, label: "Uruguay" },
  {
    id: 162,
    label: "Vanuatu"
  },
  { id: 238, label: "Venezuela" },
  { id: 203, label: "Viet Nam" },
  {
    id: 239,
    label: "Wallis et Futuna"
  },
  { id: 241, label: "Yémen" },
  { id: 243, label: "Zambie" },
  { id: 207, label: "Zimbabwe" }
];
