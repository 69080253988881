import React, { useState, useEffect } from "react";
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { TimePicker } from '@syncfusion/ej2-calendars';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import {createElement, isNullOrUndefined,Internationalization} from '@syncfusion/ej2-base';
import { v4 as uuidv4 } from 'uuid';
import {Schedule, Day, Week, WorkWeek, Month, PopupOpenEventArgs, Timezone} from '@syncfusion/ej2-schedule';
import {
    ScheduleComponent,
    ViewsDirective,
    ViewDirective,
    Agenda,
    TimelineViews,
    TimelineMonth,
    Inject,
    Resize,
    DragAndDrop,
    EventSettingsModel,
    CellClickEventArgs
} from "@syncfusion/ej2-react-schedule";
import {TreeViewComponent,DragAndDropEventArgs} from "@syncfusion/ej2-react-navigations";
import {
    createPlanning,
    getUsers,
    getPlannings,
    getExternalPlannings,
    editPlanning,
    deletePlanning,
    getProjects
} from "../../actions/planningActions";
import "./index.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import "./schedule-component.css";
import { extend } from "@syncfusion/ej2-base";
import {DatePickerComponent, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";
import { SampleBase } from "./sample-base";
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { PropertyPane } from "./property-pane";
import Grow from "@material-ui/core/Grow";
import * as dataSource from "./datasource.json";
import { DialogUtility } from '@syncfusion/ej2-popups';
import LocaleTexts from "../../Local";
import {any} from "expect";
import {DialogComponent} from "@syncfusion/ej2-react-popups";
import background from "../../assets/myteam.png";
import swal from 'sweetalert';


/**
 * Schedule timeline sample
 */

L10n.load({
    "fr-CH": {
        "schedule": {
            "day": "Jour",
            "week": "Semaine",
            "workWeek": "Work Week",
            "month": "Mois",
            "agenda": "Agenda",
            "weekAgenda": "Week Agenda",
            "workWeekAgenda": "Work Week Agenda",
            "monthAgenda": "Month Agenda",
            "today": "Aujourd'hui",
            "noEvents": "No events",
            "emptyContainer": "Aucune demande n'est prévu ce jour-là.",
            "allDay": "Toute la journée",
            "start": "Début",
            "end": "Fin de la récurrence",
            "more": "Plus",
            "close": "Close",
            "cancel": "Annuler",
            "noTitle": "(No Title)",
            "delete": "Supprimer",
            "deleteEvent": "Supprimer",
            "deleteMultipleEvent": "Supprimer plusieurs demandes",
            "selectedItems": "Articles sélectionnés",
            "deleteSeries": "Supprimer la série",
            "edit": "Modifier",
            "editSeries": "Modifier la série",
            "editEvent": "Modifier",
            "editTitle":"Modifier",
            "deleteTitle":"Supprimer",
            "createEvent": "Créer",
            "subject": "Subject",
            "addTitle": "Ajouter un titre",
            "moreDetails": "Plus de détails",
            "save": "Enregistrer",
            "editContent": "Ce bloc de temps fait partie d'une récurrence. Souhaitez-vous modifier uniquement ce bloc de temps ou toute la série ?",
            "deleteRecurrenceContent": "Voulez-vous supprimer uniquement cette demande ou toute la série ?",
            "deleteContent": "Êtes-vous sûr de vouloir supprimer cette demande ?",
            "deleteMultipleContent": "Êtes-vous sûr de vouloir supprimer les demandes sélectionnées ?",
            "newEvent": "Saisie d’un planning",
            "title": "",
            "location": "Location",
            "description": "Description",
            "timezone": "Fuseau horaire",
            "startTimezone": "Début Fuseau horaire",
            "endTimezone": "Fin Fuseau horaire",
            "repeat": "Récurrence",
            "saveButton": "Enregistrer",
            "cancelButton": "Annuler",
            "deleteButton": "Supprimer",
            "recurrence": "Récurrence",
            "wrongPattern": "Le modèle de récurrence n'est pas valide.",
            "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
            "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
            "sameDayAlert": "Deux occurrences du même événement ne peuvent pas se produire le même jour.",
            "editRecurrence": "Modifier la récurrence",
            "repeats": "Répète",
            "alert": "Alert",
            "startEndError": "La date de fin sélectionnée est antérieure à la date de début.",
            "invalidDateError": "La valeur de date saisie n'est pas valide.",
            "ok": "Ok",
            "occurrence": "Occurrence",
            "series": "Series",
            "previous": "Précédent",
            "next": "Suivant",
            "timelineDay": "Jour",
            "timelineWeek": "Semaines",
            "timelineWorkWeek": "Timeline Work Week",
            "timelineMonth": "Timeline Month"
        },
        "recurrenceeditor": {
            "none": "Aucune",
            "daily": "Quotidienne",
            "weekly": "Hebdomadaire",
            "monthly": "Mensuelle",
            "month": "Mois",
            "yearly": "Annuelle",
            "never": "Non",
            "until": "Oui, jusqu’au",
            "count": "",
            "first": "Première",
            "second": "Deuxième",
            "third": "Troisième",
            "fourth": "Quatrième",
            "last": "Dernière",
            "repeat": "Récurrence",
            "repeatEvery": "Périodicité de la récurrence",
            "on": "Journées concernés",
            "end": "Fin de la récurrence",
            "onDay": "Jour",
            "days": "Jour(s)",
            "weeks": "Semaine(s)",
            "months": "Mois",
            "years": "Année(s)",
            "every": "Chaque",
            "summaryTimes": "Temps(s)",
            "summaryOn": "sur",
            "summaryUntil": "Jusqu'à",
            "summaryRepeat": "Repeats",
            "summaryDay": "Jour(s)",
            "summaryWeek": "Semaine(s)",
            "summaryMonth": "Mois",
            "summaryYear": "Année(s)",
            "editEvent": "Modifier",
        },
        "formValidator": {
            "required" : "Ce champs est obligatoire."
        }
    }
});


class TimelineView extends SampleBase {
    constructor() {
        super(...arguments);
        console.log("DataSource" ,dataSource.timelineData );
        this.data = extend(
            [],
            this.localData.dataSource,
            null,
            true
        );
        console.log("this.localdata : ",this.localData.dataSource);
        console.log("this.props.plannings : ",this.props.plannings);
        console.log("props.equipes",this.props.equipes);
        console.log("props.userrrrrrrrrrr",this.props.user);
        this.state = {
              startDate: new Date()
            , endDate: new Date()
        };
    }
    componentDidMount() {
        this.scheduleObj.eventSettings.enableTooltip = true;
        console.log("this.props.listSelectedAgents :",this.props.listSelectedAgents);
        if(this.props.user===undefined && this.props.currentTeam){
            this.scheduleObj.refreshEvents();
            console.log("this.props.currentTeam. ==> ",this.props.currentTeam );
            this.props.getPlannings(this.props.currentTeam.teamsId);
        }
        else if(this.props.user){
            this.scheduleObj.refreshEvents();
            console.log("depart undefined",this.props.user);
            this.props.getPlannings(this.props.user.userName);
            this.props.getExternalPlannings(this.props.user.userName);
        }
        else {
            console.log("component did mount this.props :",this.props);
        }
        this.props.setSwitchTo(false);
    }

    componentDidUpdate(prevProps) {
        // Utilisation classique (pensez bien à comparer les props) :
        if (this.props.switchTo !== prevProps.switchTo) {
            if(this.props.switchTo === true){
                this.scheduleObj.eventSettings.dataSource = this.props.externalPlannings;
                this.scheduleObj.refreshEvents();
            }
            else{
                this.scheduleObj.eventSettings.dataSource = this.props.plannings;
                this.scheduleObj.refreshEvents();
            }
           console.log("Timeline switchTo :",this.props.switchTo);
           console.log("Timeline this.scheduleObj.eventSettings :",this.scheduleObj.eventSettings);
        }
    }


    onActionComplete(args){
        console.log("on action complete",args);
        if(args.requestType === 'eventChanged'){
            if (!isNullOrUndefined(args.data[0].RecurrenceRule) && !isNullOrUndefined(args.data[0].RecurrenceException) ) {
                this.props.editPlanning(args.changedRecords[0]);
                args.addedRecords[0].RecurrenceRule = null;
                args.addedRecords[0].RecurrenceException = null;
                args.addedRecords[0].RecurrenceID = null;
                this.props.createPlanning(args.addedRecords[0]);
            }
            else
                this.props.editPlanning(args.data[0]);
        }
        else if(args.requestType === 'eventRemoved'){
            if (!isNullOrUndefined(args.changedRecords[0]) && !isNullOrUndefined(args.changedRecords[0].RecurrenceRule) && !isNullOrUndefined(args.changedRecords[0].RecurrenceException) ) {
                this.props.editPlanning(args.changedRecords[0]);
            }
            else if (!isNullOrUndefined(args.deletedRecords[0]) && isNullOrUndefined(args.changedRecords[0]) ) {
                this.props.deletePlanning(args.deletedRecords[0].Id);
            }
        }
        if (args.requestType === 'dateNavigate') { //while editing the existing event
            let date = this.scheduleObj.getCurrentViewDates()[0];
            console.log("view dates : ",date);
            this.props.setListSelectedAgents([]);
            this.props.setListObjectsSelectedAgents([]);
            this.props.setDate(date);
        }
        else if (args.requestType === 'viewNavigate') { //while editing the existing event
            let date = this.scheduleObj.getCurrentViewDates()[0];
            console.log("view navigate : ",date);
            if(this.scheduleObj.currentView === "Week" || this.scheduleObj.currentView === "TimelineDay" )
            {
                this.scheduleObj.scrollTo('09:00');
            }

        }

    }


    localData :  EventSettingsModel = {
        dataSource:[
            {
                Id:"7616-7276262-gyghhga-81716",
                StartTime:"2021-01-27T09:30:00.000Z",
                EndTime:"2021-01-27T12:30:00.000Z",
                Subject:"Pause",
                RecurrenceRule:"FREQ=DAILY",
                IsReadonly:true,
                source:"Teleopti",
                "CategoryColor": "#8fa2b8"
            },{
                Id:2,
                StartTime:"2020-10-12T06:30:00.000Z",
                EndTime:"2020-10-12T07:30:00.000Z",
                Subject:"Production",
                RecurrenceRule:"FREQ=DAILY",
                "CategoryColor": "#d5126d"
            },{
                Id:3,
                StartTime:"2020-10-12T07:30:00.000Z",
                EndTime:"2020-10-12T08:30:00.000Z",
                Subject:"Production",
                RecurrenceRule:"FREQ=DAILY",
                "CategoryColor": "#4c73a1"
            },{
                Id:4,
                StartTime:"2020-10-12T08:30:00.000Z",
                EndTime:"2020-10-12T09:30:00.000Z",
                Subject:"Production",
                RecurrenceRule:"FREQ=DAILY",
                "CategoryColor": "#4dd235"
            },{
                Id:5,
                StartTime:"2020-10-12T09:30:00.000Z",
                EndTime:"2020-10-12T10:30:00.000Z",
                Subject:"Production",
                RecurrenceRule:"FREQ=DAILY",
                "CategoryColor": "#8d0606"
            },{
                Id:6,
                StartTime:"2020-10-12T10:30:00.000Z",
                EndTime:"2020-10-11:30:00.000Z",
                Subject:"Production",
                RecurrenceRule:"FREQ=DAILY"
            },]
    }
    fields = {
        subject: { name: 'Subject', validation: { required: true} },
    };
    filterPlannings(startTime,endTime) {
        let plannings = this.props.plannings;
        let planningswithoccurences = [];
        let scheduleObj = this.scheduleObj;
        //console.log("plannings without occurences : ",plannings)
        for(let i = 0;i<plannings.length;i++){
            let data = plannings[i];
            if (!isNullOrUndefined(data.RecurrenceRule)) {
                data.StartTime = new Date(data.StartTime);
                data.EndTime = new Date(data.EndTime);
                //console.log("control recurrence RecurrenceRule : ", data.RecurrenceRule);
                let occurrences = scheduleObj.eventBase.generateOccurrence(data);
                for(let j = 0;j<occurrences.length;j++){
                    planningswithoccurences.push(occurrences[j]);
                }
            }
        }
        for(let j = 0;j<plannings.length;j++){
            planningswithoccurences.push(plannings[j]);
        }
        //console.log("plannings and occurences : ",planningswithoccurences);
        let filteredPlannings = [];
        for(let i = 0;i<planningswithoccurences.length;i++){
            let planningStartTime = new Date(planningswithoccurences[i].StartTime);
            let planningEndTime = new Date(planningswithoccurences[i].EndTime);
            startTime = new Date(startTime);
            endTime = new Date(endTime);
            if(
                (startTime > planningStartTime && startTime < planningEndTime)
                || (endTime > planningStartTime && endTime < planningEndTime)
                || (planningStartTime > startTime && planningStartTime < endTime)
                || (planningEndTime > startTime && planningEndTime < endTime)
                || (planningStartTime === startTime )
                || (planningEndTime === endTime )

            ){
                /*console.log("plannings to filter : ",plannings[i]);
                console.log("startTime:"+startTime+" planningStartTime:"+planningStartTime+" endTime:"+endTime+" planningEndTime:"+planningEndTime+" ");
                console.log("condition1 (startTime > planningStartTime && startTime < planningEndTime)",(startTime > planningStartTime && startTime < planningEndTime));
                console.log("condition2 (endTime > planningStartTime && endTime < planningEndTime)",(endTime > planningStartTime && endTime < planningEndTime));
                console.log("condition3 (planningStartTime > startTime && planningStartTime < endTime)",(planningStartTime > startTime && planningStartTime < endTime));
                console.log("condition4 (planningEndTime > startTime && planningEndTime < startTime)",(planningEndTime > startTime && planningEndTime < endTime));
                console.log("condition5 (planningStartTime == startTime )",(planningStartTime === startTime ));
                console.log("condition6 (planningEndTime == endTime )",(planningEndTime === endTime ));*/
                filteredPlannings.push(planningswithoccurences[i]);
            }
        }
        return filteredPlannings;
    }
    onActionBegin(args) {
        console.log("onActionBegin event : ",args.requestType);


        let timezone: Timezone = new Timezone();
        console.log("event : ",args);
        if (args.requestType === 'eventChange') { //while editing the existing event
            console.log("event edittttttttt");
            console.log("args edit",args);
            if (args.data !== null && args.data !== undefined) {
                console.log("event edittttttttt datalength");
                let eventData = args.data;
                let eventField = this.scheduleObj.eventFields;
                let startDate = eventData[eventField.startTime];
                let endDate = eventData[eventField.endTime];
                console.log("start date : " +startDate + "end date : " +endDate);
                console.log("RecurrenceRule : " ,eventField.RecurrenceRule);

                if(!this.scheduleObj.isSlotAvailable(startDate, endDate)){
                    console.log("event edittttttttt slotavailable");
                    args.cancel = !this.scheduleObj.isSlotAvailable(startDate, endDate);
                    //alert("Vous ne pouvez pas créer un autre événement sur ce block !");
                    swal("Attention !", "Vous avez déjà saisi un planning sur ce créneau horaire", "error");
                }
                //this.scheduleObj.open

            }
        }
        if (args.requestType === 'eventCreate') { //while creating new event



            let job = this.props.currentUser.job;

            if(this.props.user === undefined && this.props.currentTeam){
                console.log("user is null")
                if(job === 'IN12012' || job === 'IN12013' )
                    args.data[0].AssignmentLevel = "TEAM_SUPPORT";
                else
                    args.data[0].AssignmentLevel = "TEAM";
                args.data[0].OwnerId = this.props.currentTeam.teamsId
            }
            else if(this.props.user){
                args.data[0].OwnerId = this.props.user.userName
                if(job === 'IN12012' || job === 'IN12013' )
                    args.data[0].AssignmentLevel = "SUPPORT";
                else
                    args.data[0].AssignmentLevel = "AGENT";
            }
            else
            {
                args.data[0].OwnerId = this.props.currentSite.siteId;
                args.data[0].AssignmentLevel = "SITE";
            }
            let fullName = this.props.currentUser.firstName + " " +this.props.currentUser.lastName +"_"+ this.props.currentUser.userName;
            args.data[0].ChangedBy = fullName;
            args.data[0].Id = uuidv4();
            console.log("event create");
            console.log("actionBegin args : ", args.data[0]);
            let startdate: Date = args.data[0].StartTime;
            let enddate: Date = args.data[0].EndTime;
            args.data[0].StartTime = timezone.removeLocalOffset(startdate);
            args.data[0].EndTime = timezone.removeLocalOffset(enddate);

            //let cellData: CellClickEventArgs = this.scheduleObj.getCellDetails(args.target);
            if (args.data[0].Subject && args.data[0].Subject === "Production" && args.data[0].startPause && args.data[0].endPause) {
                console.log("inside if : ", args.data[0]);
                //FREQ=DAILY;INTERVAL=1;UNTIL=20201212T090000Z;
                let recRule = args.data[0].RecurrenceRule;
                let newRecRule = null;
                if(!isNullOrUndefined(recRule)){
                    let recTab = recRule.split("UNTIL=");//recTab[0] = "FREQ=DAILY;INTERVAL=1;" and recTab[1] = "20201212T090000Z;"
                    let time = recTab[1];
                    let recTime = time.split("T");//recTime[0] = "20201212" and recTime[1] = "090000Z;"
                    let newTime = "235900;";
                    newRecRule = recTab[0] + "UNTIL="+recTime[0]+"T"+ newTime;
                    console.log("new Recurrence rule : ", newRecRule);

                }
                let eventProd1: { [key: string]: Object } = {
                    Id:uuidv4(),
                    Subject: "Production",
                    StartTime: args.data[0].endPause && args.data[0].endPause,
                    EndTime: enddate,
                    OwnerId:args.data[0].OwnerId,
                    AssignmentLevel:args.data[0].AssignmentLevel,
                    RecurrenceRule: newRecRule !== null ? newRecRule : args.data[0].RecurrenceRule,
                    CategoryColor :"#7bed9f"
                }
                let eventPause: { [key: string]: Object } = {
                    Id:args.data[0].Id,
                    Subject: "Pause",
                    StartTime: args.data[0].startPause,
                    EndTime: args.data[0].endPause,
                    OwnerId:args.data[0].OwnerId,
                    AssignmentLevel:args.data[0].AssignmentLevel,
                    CategoryColor: "#4fc3f7",
                    RecurrenceRule: newRecRule !== null ? newRecRule : args.data[0].RecurrenceRule,
                    //FREQ=DAILY;INTERVAL=1;UNTIL=20201212T090000Z;
                }
                this.scheduleObj.addEvent(eventProd1);
                this.scheduleObj.addEvent(eventPause);
                args.data[0].EndTime = timezone.removeLocalOffset(args.data[0].startPause);
                enddate = args.data[0].startPause;
                //this.scheduleObj.openEditor(eventData,"Add",true);

                //this.scheduleObj.openEditor(eventData,"Add",true);

                args.data[0].CategoryColor = "#7bed9f"
            }
            else if (args.data[0].Subject && args.data[0].Subject === "Production" && (!args.data[0].startPause || !args.data[0].startPause) )
                args.data[0].CategoryColor = "#7bed9f"

            if (args.data[0].Subject && args.data[0].Subject === "Pause") {
                args.data[0].CategoryColor = "#4fc3f7"
            }

            if (args.data[0].Subject && args.data[0].Subject === "Day off") {
                args.data[0].CategoryColor = "#757575";
                let startTime = timezone.removeLocalOffset(new Date(args.data[0].StartTime.getFullYear(),args.data[0].StartTime.getMonth(),args.data[0].StartTime.getDate(),0,0));
                let endTime = timezone.removeLocalOffset(new Date(args.data[0].StartTime.getFullYear(),args.data[0].StartTime.getMonth(),args.data[0].StartTime.getDate(),23,59));
                args.data[0].StartTime = startTime;
                args.data[0].EndTime = endTime;
                args.data[0].IsAllDay = true;
            }

            if (args.data[0].Subject && args.data[0].Subject === "Repas") {
                args.data[0].CategoryColor = "#bfbfbf"
            }

            if (args.data[0].Subject && args.data[0].Subject === "Allaitement") {
                args.data[0].CategoryColor = "#4f4f4f"
            }

            if (args.data.length > 0) {
                let eventData = args.data[0];
                let eventField = this.scheduleObj.eventFields;
                let startDate = startdate;
                let endDate = enddate;

                let data = args.data instanceof Array ? args.data[0] : args.data;
                //console.log("control recurrence data: ",data);
                let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
                //let scheduleObj = this.scheduleObj;
                if(!this.scheduleObj.isSlotAvailable(startDate, endDate)){
                    console.log("event create slotavailable",args.data[0]);
                    args.cancel = !this.scheduleObj.isSlotAvailable(startDate, endDate);
                    //alert("Vous ne pouvez pas créer un autre événement sur ce block !");
                    swal("Attention !", "Vous avez déjà saisi un planning sur ce créneau horaire", "error");
                }
                else if(args.data[0] && (args.data[0].Subject === null || args.data[0].Subject === undefined || args.data[0].Subject ==='Ajouter un titre' )){
                    console.log("event create subject undefined");
                    args.cancel = true ;
                    swal("Choisissez un type d'activité",'',"warning");
                }
                else if (!isNullOrUndefined(data.RecurrenceRule) && (!this.props.listSelectedAgents || this.props.listSelectedAgents.length === 0) ) {
                    //console.log("control recurrence RecurrenceRule: ",data.RecurrenceRule);
                    let occurrences = scheduleObj.eventBase.generateOccurrence(data);

                    //console.log("control recurrence occurrences: ",occurrences);
                    //console.log("control recurrence scheduleObj.eventBase: ",scheduleObj.eventBase);
                    let createPlanning = true;
                    for (let i = 0; i < occurrences.length; i++) {
                        // Get the timeslot and check if there is already an event
                        //this.props.plannings

                        let filteredEventsFromScheduler = scheduleObj.eventBase.filterEvents(
                            occurrences[i].StartTime,
                            occurrences[i].EndTime
                        );
                        let filteredEvents = this.filterPlannings(
                            occurrences[i].StartTime,
                            occurrences[i].EndTime
                        );
                        if (filteredEvents.length > 0 ) {
                            //console.log("control recurrence filteredEvents,occurrences[i]: ",occurrences[i]);
                            //console.log("control recurrence filtered plannings fromdatabase ",filteredEvents);
                            //console.log("control recurrence filtered plannings from scheduler : ",filteredEventsFromScheduler);
                            args.cancel = true;
                            swal("Attention !", "Chevauchement sur la date : "+occurrences[i].StartTime.getDate()+"/"+(occurrences[i].StartTime.getMonth()+1) +"/"+occurrences[i].StartTime.getFullYear()+"", "error");
                            createPlanning = false;
                            break;
                        }
                    }
                    if(createPlanning){
                        let recurrenceRule = data.RecurrenceRule;
                            if(!isNullOrUndefined(recurrenceRule)){
                                let recTab = recurrenceRule.split("UNTIL=");
                                let time = recTab[1];
                                let recTime = time.split("T");
                                let newTime = "235900;";
                                let newRecurrenceRule = recTab[0] + "UNTIL="+recTime[0]+"T"+ newTime;
                                console.log("new Recurrence rule v2 : ", newRecurrenceRule);
                                args.data[0].RecurrenceRule = newRecurrenceRule;
                            }
                        this.props.createPlanning(args.data[0]);
                    }
                }
                else {
                    const listSelectedAgents = this.props.listSelectedAgents;
                    const listSelectedEquipes = this.props.listSelectedEquipes;
                    if(listSelectedAgents && listSelectedAgents.length > 0 && !this.props.user){
                        for( var i = 0; i < listSelectedAgents.length; i++){
                            args.data[0].OwnerId = listSelectedAgents[i];
                            args.data[0].Id = uuidv4();
                            console.log("iddddddd : ",uuidv4());
                            if(job === 'IN12012' || job === 'IN12013' )
                                args.data[0].AssignmentLevel = "SUPPORT";
                            else
                                args.data[0].AssignmentLevel = "AGENT";
                            let recurrenceRule = args.data[0].RecurrenceRule;
                            if(!isNullOrUndefined(recurrenceRule)){
                                let recTab = recurrenceRule.split("UNTIL=");
                                let time = recTab[1];
                                let recTime = time.split("T");
                                let newTime = "235900;";
                                let newRecurrenceRule = recTab[0] + "UNTIL="+recTime[0]+"T"+ newTime;
                                console.log("new Recurrence rule v2 : ", newRecurrenceRule);
                                args.data[0].RecurrenceRule = newRecurrenceRule;
                            }
                            this.props.createPlanning(args.data[0]);
                        }
                    }
                    else if(listSelectedEquipes && listSelectedEquipes.length > 0 && !this.props.user){
                        for( var i = 0; i < listSelectedEquipes.length; i++){
                            args.data[0].OwnerId = listSelectedEquipes[i];
                            args.data[0].Id = uuidv4();
                            if(job === 'IN12012' || job === 'IN12013' )
                                args.data[0].AssignmentLevel = "TEAM_SUPPORT";
                            else
                                args.data[0].AssignmentLevel = "TEAM";
                            this.props.createPlanning(args.data[0]);
                        }
                    }
                    else{
                        console.log("Before calling WS",args.data[0]);
                        console.log("Before calling WS Args",args);
                        console.log("Before calling WS addedRecords",args.addedRecords[0]);
                        console.log("Before calling WS addedRecords.StartTime",args.addedRecords[0].StartTime);
                        console.log("Before calling WS StartTime",args.data[0].StartTime);

                        this.props.createPlanning(args.data[0]);

                    }

                }
                args.data[0].StartTime = startdate;
                args.data[0].EndTime = enddate;
                //this.scheduleObj.open

            }


        }
        if (args.requestType === 'eventRemove') { //while editing the existing event
            console.log("args remove",args);
        }
    }

    popupClose(args){
        console.log("popupclose args : ",args);
    }
    onEventCreate(args){
        //console.log("data for eventrendered in create : ");
        console.log("onEventRendered in create------" , args );
    }
    onEventRendered(args) {
        // console.log("data for eventrendered : ",this.data);
        //console.log("onEventRendered ------" , args );
        //this.props.createPlanning(args);

        console.log("rendered",args);
        const newProject = {
            projectName: "name"
        };
        //this.props.getUsers(args.data);

        let categoryColor = args.data.CategoryColor;
        if (!args.element || !categoryColor) {
            return;
        }
        if (this.scheduleObj.currentView === "Agenda") {
            args.element.firstChild.style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }

    change(args) {
        console.log("test ");
        this.scheduleObj.selectedDate = args.value;
        this.scheduleObj.dataBind();
    }

    popupOpen = (args) => {
        console.log("args for popup open : ",args);
        if ((this.scheduleObj.currentView === "Month" || this.scheduleObj.currentView === "Week") && !args.data.Subject && args.type !== "EventContainer" ) {
            console.log("Current view : ",this.scheduleObj.currentView);
            args.cancel=true;
        }
        //((args.data)).StartTime && console.log("min date : ",new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate(),0,0))
        //((args.data)).StartTime && console.log("max date : ",new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate(),23,60))


        //this.scheduleObj.eventWindow.recurrenceEditor.startDate = ((args.data)).StartTime && new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate(),23,59);
        //console.log("recurrence editor",this.scheduleObj.eventWindow.recurrenceEditor);
        //hide pause row at the popup open
        let pauseRow1 = args.element.querySelector('.e-start-end-pause-row');
        if (pauseRow1) {
            pauseRow1.style.display = 'none';
        }
        //===============================
        console.log("args inside hide : ",args.data);
        if (args.data && !args.data.Id) {
            console.log("args inside hide 2 : ",args.data)
            let eventData = args.data;
            let startDate = eventData.StartTime;
            let endDate = eventData.EndTime;
            if(startDate && !this.scheduleObj.isSlotAvailable(startDate, endDate)){
                args.cancel = !this.scheduleObj.isSlotAvailable(startDate, endDate);
                //alert("Vous ne pouvez pas créer un autre événement sur ce block !");
                swal("Attention !", "Vous avez déjà saisi un planning sur ce créneau horaire", "error");
            }

            //this.scheduleObj.open
        }
        console.log("args.type for popup open : ",args.type);
        console.log("args for popup open : ",args);

        if (args.type === 'Editor') {
            //change the recurrence data

            this.scheduleObj.eventWindow.recurrenceEditor.endType.dataSource = [
                {text: "Non", value: "never"},
                {text: "Oui, jusqu’au", value: "until"}
            ];
            console.log("recurrence : ", this.scheduleObj.eventWindow.recurrenceEditor.endType.dataSource);
            this.scheduleObj.eventWindow.recurrenceEditor.frequencies = ['none', 'daily', 'weekly'];
            console.log("this.scheduleObj.eventWindow.recurrenceEditor : ",this.scheduleObj.eventWindow.recurrenceEditor);
            console.log("open editor args.data : ",args.data);
            // rebuild startEndRow for customization
            let startEndRow = args.element.querySelector('.e-start-end-row');
            startEndRow.innerHTML = '';

            //let startEndPauseRow = createElement('div', {className: 'e-start-end-row e-start-end-pause-row'});
            let containerStartTime = createElement('div', {className: 'e-start-container'});
            let floatContainerStartTime = createElement('div', {className: 'e-float-input e-control-wrapper e-datetime-wrapper e-valid-input'});
            //let labelStart = createElement('label', {className:'e-float-text e-label-top', attrs: { for: 'startPause', id:'startPauseLabel'} ,innerHTML:'Début pause'});
            let inputStartTime = createElement('input', {
                className: 'e-start e-field',
                attrs: {name: 'StartTime', id: 'StartTime', placeHolder: 'Début'}
            });
            inputStartTime.value = ((args.data)).StartTime;

            //this.setState({startDate:((args.data)).StartTime, endDate:((args.data)).EndTime});
            console.log("input start time : ",((args.data)).StartTime);
            //floatContainerStart.appendChild(labelStart);
            floatContainerStartTime.appendChild(inputStartTime);
            containerStartTime.appendChild(floatContainerStartTime);
            startEndRow.appendChild(containerStartTime);
            let startTime = new DateTimePicker(
                {
                    floatLabelType: 'Always',
                    placeholder: 'Début',
                    value: new Date(inputStartTime.value),
                    format:'HH:mm',
                    locale:'fr-CH',
                    allowEdit:false,
                    min: new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate(),0,0),
                    max : new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate()+1,23,59)
                });

            startTime.appendTo(inputStartTime);
            inputStartTime.setAttribute('name', 'StartTime');



            let containerEndTime = createElement('div', {className: 'e-end-container'});
            //e-float-input e-control-wrapper e-input-group e-datetime-wrapper e-valid-input
            let floatContainerEndTime = createElement('div', {className: 'e-float-input e-control-wrapper e-datetime-wrapper e-valid-input'});

            let inputEndTime = createElement('input', {
                className: 'e-end e-field e-control',
                attrs: {name: 'EndTime', id: 'EndTime', placeHolder: 'Fin'}
            });
            inputEndTime.value = ((args.data)).EndTime;
            containerEndTime.appendChild(floatContainerEndTime);
            floatContainerEndTime.appendChild(inputEndTime);
            startEndRow.appendChild(containerEndTime);
            let endElementTime = new DateTimePicker(
                {
                    floatLabelType: 'Always',
                    placeholder: 'Fin' ,
                    value: new Date(inputEndTime.value),
                    format:'HH:mm',
                    locale:'fr-CH',
                    allowEdit:false,
                    min: new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate(),0,0),
                    max : new Date(((args.data)).StartTime.getFullYear(),((args.data)).StartTime.getMonth(),((args.data)).StartTime.getDate()+1,23,59)
                },inputEndTime);
            //endElement.appendTo(inputEnd);
            inputEndTime.setAttribute('name', 'EndTime');
            //======================================================================
            //end rebuild startEndRow for customization

            let subjectElement = args.element.querySelector('#Subject');
            let dropDownListObject = null;

            if (subjectElement && subjectElement.classList && !subjectElement.classList.contains('e-dropdownlist')) {
                if(args.data.Subject && args.data.Subject === "Pause" ){
                    console.log("if1 : args.data.Subject && args.data.Subject === \"Pause\"");
                    dropDownListObject = new DropDownList({
                        placeholder: "Choisissez le type d’activité",
                        value: subjectElement.value,
                        dataSource: [
                            {text: 'Pause', value: 'pause'}
                        ]
                    }, subjectElement);
                }
                else {
                    console.log("else1");
                    dropDownListObject = new DropDownList({
                        placeholder: "Choisissez le type d’activité",
                        value: subjectElement.value,
                        dataSource: [
                            {text: 'Production', value: 'production'},
                            {text: 'Day off', value: 'day-off'},
                            {text: 'Repas', value: 'repas'},
                            {text: 'Allaitement', value: 'allaitement'}
                        ]
                    }, subjectElement);
                }

                //dropDownListObject.appendTo(subjectElement);
                console.log("before dropdown args : ",args.data)

            }
            else {
                console.log("new args : ",args);

                let floatContainerStart = args.element.querySelector('#floatStartPause');
                let floatContainerEnd = args.element.querySelector('#floatEndPause');
                if(floatContainerStart && floatContainerEnd){
                    console.log("floatContainerStartAndEnd : ");
                    floatContainerStart.innerHTML = '';
                    let inputStart = createElement('input', {
                        className: 'e-start e-field',
                        attrs: {name: 'startPause', id: 'startPause', placeHolder: 'Début pause'}
                    });
                    floatContainerStart.appendChild(inputStart);
                    let startElement = new DateTimePicker(
                        {
                            floatLabelType: 'Always',
                            placeholder: 'Début pause',
                            value: new Date(((args.data)).StartTime),
                            min:new Date(((args.data)).StartTime),
                            max: new Date(((args.data)).EndTime),
                            format:'HH:mm',
                            step : 5,
                            locale:'fr-CH',
                            allowEdit:false
                        });
                    startElement.appendTo(inputStart);
                    inputStart.setAttribute('name', 'startPause');

                    floatContainerEnd.innerHTML = '';
                    let inputEnd = createElement('input', {
                        className: 'e-end e-field e-control',
                        attrs: {name: 'endPause', id: 'endPause', placeHolder: 'Fin pause'}
                    });
                    floatContainerEnd.appendChild(inputEnd);
                    let endElement = new DateTimePicker(
                        {
                            floatLabelType: 'Always',
                            placeholder: 'Fin pause' ,
                            value: new Date(((args.data)).EndTime),
                            min:new Date(((args.data)).StartTime),
                            max: new Date(((args.data)).EndTime),
                            format:'HH:mm' ,
                            step : 5,
                            locale:'fr-CH',
                            allowEdit:false
                        },inputEnd);
                    //endElement.appendTo(inputEnd);
                    inputEnd.setAttribute('name', 'endPause');


                    startTime.change = () => {
                        console.log("startTime : ",startTime);
                        let minHours = startTime.value;
                        //minMinutes = startTime.value.getMinutes();
                        endElementTime.min = minHours;
                        if(minHours > endElementTime.value)
                            endElementTime.value = new Date(minHours.getFullYear(),minHours.getMonth(),minHours.getDate(),minHours.getHours(),minHours.getMinutes()+5);
                        startElement.min = new Date(minHours);
                        endElement.min = new Date(minHours);
                        startElement.max = new Date(endElementTime.value);
                        endElement.min = new Date(endElementTime.value);
                        startElement.step = 5;
                        endElement.step = 5;
                        //console.log("inside start change",new Date(minHours));
                    }
                    endElementTime.change = () => {
                        let maxHours = endElementTime.value;
                        //minMinutes = startTime.value.getMinutes();
                        startTime.max = maxHours;
                        if(maxHours < startTime.value)
                            startTime.value = new Date(maxHours.getFullYear(),maxHours.getMonth(),maxHours.getDate(),maxHours.getHours(),maxHours.getMinutes()-5);
                        startElement.min = new Date(startTime.value);
                        endElement.min = new Date(startTime.value);
                        startElement.max = new Date(maxHours);
                        endElement.max = new Date(maxHours);
                        startElement.step = 5;
                        endElement.step = 5;
                        //console.log("inside start change",new Date(maxHours));
                    }
                    startElement.change = () => {
                        let maxHours = endElementTime.value;
                        let minHours = startElement.value;
                        //minMinutes = startTime.value.getMinutes();
                        endElement.min = new Date(minHours);
                        endElement.max = new Date(maxHours);
                        startElement.step = 5;
                        endElement.step = 5;
                        //console.log("inside start change",new Date(maxHours));
                    }
                }
                //e-subject-container
                let floatContainerSubject = args.element.querySelector('.e-subject-container');
                floatContainerSubject.innerHTML = '';
                let inputSubject = createElement('input', {
                    className: 'e-subject e-field e-lib e-control e-input',
                    attrs: {name: 'Subject', id: 'Subject', placeHolder: ''}
                });
                floatContainerSubject.appendChild(inputSubject);

                if(args.data.Subject && args.data.Subject === "Pause" ){
                    console.log("args.data.Subject && args.data.Subject === \"Pause\"");
                    dropDownListObject = new DropDownList({
                        placeholder: "Choisissez le type d’activité",
                        value: args.data.Subject,
                        dataSource: [
                            {text: 'Pause', value: 'pause'}
                        ]
                    }, inputSubject);
                }
                else {
                    console.log("else of else");
                    dropDownListObject = new DropDownList({
                        placeholder: "Choisissez le type d’activité",
                        value: args.data.Subject,
                        dataSource: [
                            {text: 'Production', value: 'production'},
                            {text: 'Day off', value: 'day-off'},
                            {text: 'Repas', value: 'repas'},
                            {text: 'Allaitement', value: 'allaitement'}
                        ]
                    }, inputSubject);
                }
                inputSubject.setAttribute('name', 'Subject');
            }

            if(dropDownListObject !== null) {
                dropDownListObject.change = function (args2) {
                    console.log("after dropdown args2 : ", args2);
                    let pauseRow = args.element.querySelector('.e-start-end-pause-row');
                    let startEndRow = args.element.querySelector(".e-start-end-row");
                    if (args2.value === "Production") {
                        if (startEndRow) {
                            startEndRow.style.display = '';
                        }
                        if (!pauseRow) {
                            console.log("inside pause row : ", args.data);
                            let minHours = null;
                            let maxHours = null;
                            let startEndPauseRow = createElement('div', {className: 'e-start-end-row e-start-end-pause-row'});
                            let formElement = args.element.querySelector('.e-schedule-form');
                            formElement.firstChild.insertBefore(startEndPauseRow, args.element.querySelector('.e-all-day-time-zone-row'));

                            let containerStart = createElement('div', {className: 'e-start-container'});
                            let floatContainerStart = createElement('div', {
                                className: 'e-float-input e-control-wrapper e-datetime-wrapper e-valid-input',
                                id: 'floatStartPause'
                            });
                            //let labelStart = createElement('label', {className:'e-float-text e-label-top', attrs: { for: 'startPause', id:'startPauseLabel'} ,innerHTML:'Début pause'});
                            let inputStart = createElement('input', {
                                className: 'e-start e-field',
                                attrs: {name: 'startPause', id: 'startPause', placeHolder: 'Début pause'}
                            });
                            //floatContainerStart.appendChild(labelStart);
                            floatContainerStart.appendChild(inputStart);
                            containerStart.appendChild(floatContainerStart);
                            startEndPauseRow.appendChild(containerStart);
                            let startElement = new DateTimePicker({
                                floatLabelType: 'Always',
                                placeholder: 'Début pause',
                                value: new Date(((args.data)).StartTime),
                                min: new Date(((args.data)).StartTime),
                                max: new Date(((args.data)).EndTime),
                                format:'HH:mm',
                                locale:'fr-CH',
                                step: 5,
                                allowEdit:false
                            });
                            startElement.appendTo(inputStart);
                            inputStart.setAttribute('name', 'startPause');

                            let containerEnd = createElement('div', {className: 'e-end-container'});
                            //e-float-input e-control-wrapper e-input-group e-datetime-wrapper e-valid-input
                            let floatContainerEnd = createElement('div', {
                                className: 'e-float-input e-control-wrapper e-datetime-wrapper e-valid-input',
                                id: 'floatEndPause'
                            });

                            let inputEnd = createElement('input', {
                                className: 'e-end e-field e-control',
                                attrs: {name: 'endPause', id: 'endPause', placeHolder: 'Fin pause'}
                            });
                            containerEnd.appendChild(floatContainerEnd);
                            floatContainerEnd.appendChild(inputEnd);
                            startEndPauseRow.appendChild(containerEnd);
                            let endElement = new DateTimePicker({
                                floatLabelType: 'Always',
                                placeholder: 'Fin pause',
                                value: new Date(((args.data)).EndTime),
                                min: new Date(((args.data)).StartTime),
                                max: new Date(((args.data)).EndTime),
                                format:'HH:mm',
                                locale:'fr-CH',
                                step: 5,
                                allowEdit:false
                            }, inputEnd);
                            //endElement.appendTo(inputEnd);
                            inputEnd.setAttribute('name', 'endPause');

                            startTime.change = () => {
                                console.log("startTime : ",startTime);
                                minHours = startTime.value;
                                //minMinutes = startTime.value.getMinutes();
                                endElementTime.min = minHours;
                                if(minHours > endElementTime.value)
                                    endElementTime.value = new Date(minHours.getFullYear(),minHours.getMonth(),minHours.getDate(),minHours.getHours(),minHours.getMinutes()+5);
                                startElement.min = new Date(minHours);
                                endElement.min = new Date(minHours);
                                startElement.max = new Date(endElementTime.value);
                                endElement.min = new Date(endElementTime.value);
                                startElement.step = 5;
                                endElement.step = 5;
                                //console.log("inside start change",new Date(minHours));
                            }
                            endElementTime.change = () => {
                                maxHours = endElementTime.value;
                                //minMinutes = startTime.value.getMinutes();
                                startTime.max = maxHours;
                                if(maxHours < startTime.value)
                                    startTime.value = new Date(maxHours.getFullYear(),maxHours.getMonth(),maxHours.getDate(),maxHours.getHours(),maxHours.getMinutes()-5);
                                startElement.min = new Date(startTime.value);
                                endElement.min = new Date(startTime.value);
                                startElement.max = new Date(maxHours);
                                endElement.max = new Date(maxHours);
                                startElement.step = 5;
                                endElement.step = 5;
                                //console.log("inside start change",new Date(maxHours));
                            }
                            startElement.change = () => {
                                let maxHours = endElementTime.value;
                                let minHours = startElement.value;
                                //minMinutes = startTime.value.getMinutes();
                                endElement.min = new Date(minHours);
                                endElement.max = new Date(maxHours);
                                startElement.step = 5;
                                endElement.step = 5;
                                //console.log("inside start change",new Date(maxHours));
                            }
                        } else {
                            pauseRow.style.display = '';
                        }
                    }
                    else if(args2.value === "Day off") {
                        let startEndRow = args.element.querySelector(".e-start-end-row");
                        if (startEndRow) {
                            startEndRow.style.display = 'none';
                        }
                        if (pauseRow) {
                            pauseRow.style.display = 'none';
                        }
                    }
                    else {
                        if (startEndRow) {
                            startEndRow.style.display = '';
                        }
                        if (pauseRow) {
                            pauseRow.style.display = 'none';
                        }
                    }
                }
            }
            let locationContainer = args.element.querySelector('.e-location-container');
            if(locationContainer){
                locationContainer.parentNode.removeChild(locationContainer);
            }

            let timeZoneAlldayRow = args.element.querySelector('.e-all-day-time-zone-row');
            if(timeZoneAlldayRow){
               timeZoneAlldayRow.style.display = 'none';
            }

        }
    }

    treeData: {[key:string]:Object}[] =[
        {Id:1,Name:"Production"},
        {Id:2,Name:"Pause"},
        {Id:3,Name:"Day off"},
        {Id:4,Name:"Repas"},
    ];

    onTreeDragStop(args:DragAndDropEventArgs) {
        let cellData: CellClickEventArgs = this.scheduleObj.getCellDetails(args.target);
        let eventData:{[key:string]:Object} = {
            Subject :args.draggedNodeData.text,
            StartTime:cellData.startTime,
            EndTime:cellData.endTime,
        }
        this.scheduleObj.openEditor(eventData,"Add",true);
        //this.scheduleObj.addEvent(eventData);
    }

    field : Object = {dataSource:this.treeData,id:'Id',text:'Name'}
    render() {
        console.log("state.startdate : ",this.state.startDate);
        console.log("data from props : ", this.props.plannings);
        return (
            <div className="schedule-control-section">
                <Grow in={true} {...{ timeout: 100}}>
                    <div className="col-lg-10 control-section">
                        <div className="control-wrapper">
                            <ScheduleComponent
                                height="500"
                                locale= "fr-CH"
                                ref={(schedule) => (this.scheduleObj = schedule)}
                                selectedDate={new Date()}
                                eventSettings={{ dataSource: this.props.plannings ? this.props.plannings : [] }}
                                eventRendered={this.onEventRendered.bind(this)}
                                eventClick={this.onEventCreate.bind(this)}
                                popupOpen={this.popupOpen}
                                popupClose={this.popupClose}
                                showQuickInfo={false}
                                actionBegin={this.onActionBegin.bind(this)}
                                actionComplete={this.onActionComplete.bind(this)}
                                timeScale={{ enable: true, interval: 60, slotCount: 12 }}
                                workHours={{ highlight: true, start: '09:00', end: '18:00' }}
                                cssClass='schedule-cell-dimension'
                                timezone="UTC"
                                showTimeIndicator={false}
                            >
                                <ViewsDirective>
                                    <ViewDirective option="TimelineDay" interval={1} isSelected={true}/>
                                    <ViewDirective option="Week" />
                                    <ViewDirective option="Month" />
                                </ViewsDirective>
                                <Inject
                                    services={[
                                        TimelineViews,
                                        //TimelineMonth,
                                        Week,
                                        Month,
                                        Day,
                                        Agenda,
                                        Resize,
                                        DragAndDrop,
                                    ]}
                                />
                            </ScheduleComponent>
                        </div>
                    </div>
                </Grow>
            </div>

        );
    }
}


TimelineView.propTypes = {
    createProject: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    errors: state.errors,
    plannings:state.project.plannings,
    externalPlannings:state.project.externalPlannings
});

export default connect(mapStateToProps,
    { createPlanning ,getUsers,getPlannings,editPlanning,deletePlanning,getExternalPlannings}
)(TimelineView);
