import * as moment from 'moment-timezone';

export default function holidaysReducer(state = {
    timeLine: [],
    publicHolidays: {totalElements: 0, data: [], loading: false},
    exceptionalHolidays: {
        totalElements: 0,
        data: [],
        loading: false
    },
    showAddExceptionalHoliday: {type: "ADD", open: false, details: {}},
    recups: {
        loading: false,
        data: [],
        details: {},
        totalElements: 1
    },
    showAddRecup: {open: false, details: {}, type: "ADD"},
    showAddHoliday: {type: "ADD", open: false, details: {}},
    timeLineStartDate: moment(new Date())
}, {type, payload}) {
    switch (type) {
        case "GET_TIMELINE":
            return {...state, timeLine: payload};
        case "GET_RECUPS":
            return {...state, recups: payload};
        case "SET_SHOW_ADD_RECUP":
            return {...state, showAddRecup: payload};
        case "SET_TIMELINE_DATE":
            return {...state, timeLineStartDate: payload};
        case "GET_PUBLIC_HOLIDAYS":
            return {...state, publicHolidays: payload};
        case "GET_EXCEPTIONAL_HOLIDAYS":
            return {...state, exceptionalHolidays: payload};
        case "SET_SHOW_EXCEPTIONAL_HOLIDAYS":
            return {...state, showAddExceptionalHoliday: payload};
        case "SET_SHOW_ADD_HOLIDAY":
            return {...state, showAddHoliday: payload};
        default:
            return state
    }
}
