
import {getAxios} from "./AuthActions";
import {
    GET_BACKUP,
    GET_ERRORS,
    GET_PROJECTS,
    GET_PROJECT,
    GET_USERS,
    DELETE_PROJECT,
    GET_PLANNING,
    GET_AVAILABILITY,
    GET_EXTERNAL_PLANNING
} from "./types";

export const createPlanning = (planning) => async dispatch => {
  try {
      const axios = getAxios();
    const res = await axios.post("https://myteamplanner.intelcia.com/api/default/", planning);
    console.log("in action  create plannign  ", planning );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: ""
    });
  }
};

export const editPlanning = (planning) => async dispatch => {
  try {
      const axios = getAxios();
    const res = await axios.put("https://myteamplanner.intelcia.com/api/default/", planning);
    console.log("in action  edit plannign  ", planning );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: ""
    });
  }
};

export const deletePlanning = (planningId) => async dispatch => {
  try {

      const axios = getAxios();
    const res = await axios.delete("https://myteamplanner.intelcia.com/api/default/"+planningId);
    console.log("in action  delete plannign  ", planningId );
  } catch (err) {
      console.log("error delete : ", err );
    dispatch({
      type: GET_ERRORS,
      payload: ""
    });
  }
};

export const getPlannings = (ownerId) => async dispatch => {
  try {

      const axios = getAxios();
      const res = await axios.get("https://myteamplanner.intelcia.com/api/default?ownerId="+ownerId);
      console.log("result in getPlanning : ",res.data);

      dispatch( {
          type: GET_PLANNING,
          payload: res.data
      })
  } catch (err) {
      console.log("erreur in get plannings :",err);
  }
};

export const getExternalPlannings = (ownerId) => async dispatch => {
    try {

        const axios = getAxios();
        const res = await axios.get("https://myteamplanner.intelcia.com/api/default?ownerId="+ownerId+"&externalPlanning=true");
        /*const res = {
            data : [{
                Id:"7616-7276262-gyghhga-81716",
                StartTime:"2021-01-27T09:30:00.000Z",
                EndTime:"2021-01-27T12:30:00.000Z",
                Subject:"Pause",
                RecurrenceRule:"FREQ=DAILY",
                IsReadonly:true,
                source:"Teleopti",
                "CategoryColor": "#8fa2b8"
            }]};*/
        console.log("result in getExternalPlanning : ",res.data);

        dispatch( {
            type: GET_EXTERNAL_PLANNING,
            payload: res.data
        })
    } catch (err) {
        console.log("erreur in get plannings :",err);
    }
};

export const getAvailability = (ownerIds,date) => async dispatch => {
  try {

      const axios = getAxios();
      console.log("in action getAvailability (ownerIds : "+ownerIds+") & (date : "+date+" )");
      const res = await axios.get("https://myteamplanner.intelcia.com/api/default/availability?ownerIds="+ownerIds+"&date="+date);
      console.log("result in getPlanning : ",res.data);

      dispatch({
          type: GET_AVAILABILITY,
          payload: res.data
      })
  } catch (err) {
      console.log("erreur in get availablity :",err);
  }
};

export const getPlanningsOutsider = () => {
    const axios = getAxios();
    return (dispatch) => {
        axios.get("https://myteamplanner.intelcia.com/default?ownerId=22008157")
            .then(res => {
                dispatch({ type: "GET_PLANNING2", payload: res.data });
            })
            .catch(err =>
                console.log("erreur in get getPlanningsOutsider :",err)
            );
    }
};



export const getProjects = (matricule,job) => async dispatch => {
  const axios = getAxios();
  const res =  await  axios.get("https://myteamplanner.intelcia.com/api/teams/"+matricule+"?job="+job);
  const result =  {
      data :
          [/*
              {
                  "site": "MA-Znt-Orange MA B2C B2B",
                  "id":1,
                  "teams" :
                      [*/

                          {
                              "site": "MA-Znt-Orange MA B2C B2B",
                              "teamsId": 4056960,
                              "teamName": "Sahara 7",
                              "employeeVOS": [{
                                  "userName": "M00090",
                                  "firstName": "Samira",
                                  "lastName": "Haida",
                                  "fullName": "Samira Haida"
                              }]
                          },
                          {
                              "site": "MA-Znt-Orange MA B2C B2B",
                              "teamsId": 20934401,
                              "teamName": "Sahara 44",
                              "employeeVOS": [{
                                  "userName": "038904",
                                  "firstName": "Salma",
                                  "lastName": "Koutayet",
                                  "fullName": "Salma Koutayet"
                              }, {
                                  "userName": "M00181",
                                  "firstName": "Driss",
                                  "lastName": "Rouijel",
                                  "fullName": "Driss Rouijel"
                              }, {
                                  "userName": "044180",
                                  "firstName": "Oussama",
                                  "lastName": "Arsaoui",
                                  "fullName": "Oussama Arsaoui"
                              }, {
                                  "userName": "038894",
                                  "firstName": "Safwane",
                                  "lastName": "Amdi",
                                  "fullName": "Safwane Amdi"
                              }, {
                                  "userName": "M00462",
                                  "firstName": "Jad",
                                  "lastName": "Menebhi",
                                  "fullName": "Jad Menebhi"
                              }, {
                                  "userName": "M00460",
                                  "firstName": "Abdelghani",
                                  "lastName": "Alkhabchi",
                                  "fullName": "Abdelghani Alkhabchi"
                              }]
                          }/*
                      ]
              }*/
          ]
  }
  console.log("res : ",res)
  dispatch({
    type: GET_PROJECTS,
    payload: res
  });
};

export const getTeamsBackup = () => async dispatch => {
  const axios = getAxios();
  const resBackup =  await  axios.get("https://myshift-back.intelcia.com:4200/api/teams/backup");

  console.log("resBackup :",resBackup);
  dispatch({
    type: GET_BACKUP,
    payload: resBackup
  });
};

export const getProjectsPlannings = () => async dispatch => {
  const axios = getAxios();
  console.log("getProjectsPlannings");
  const res =  await  axios.get("https://myteamplanner.intelcia.com/default?ownerId=22008157");
  dispatch({
    type: "GET_PLANNING9",
    payload: res.data
  });
};


export const getUsers  = (matricule) => async dispatch => {
  try {

      const axios = getAxios();
      const res = await axios.get("https://myteamplanner.intelcia.com/api/teams/"+matricule);
      console.log("result in getPlanning : ",res.data);


      dispatch( {
          type: GET_USERS,
          payload: res.data
      })
  } catch (err) {
      console.log("erreur in get users :",err);
  }
};

export const showLoaderSpinner = () => dispatch => {
    console.log("show loader");
    dispatch({
        type:"SHOW_LOADER"
    })
}

export const hideLoaderSpinner = () => dispatch => {
    console.log("hide loader");
    dispatch({
        type:"HIDE_LOADER"
    })
}
export const selectMembers = (payload) => dispatch => {
    dispatch({
        type: "SELECT_MEMBERS",
        payload: payload || []
    })
}
