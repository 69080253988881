import * as React from "react";
import { enableRipple } from "@syncfusion/ej2-base";
enableRipple(true);

export class SampleBase extends React.PureComponent {
  rendereComplete() {
    /**custom render complete function */
    console.log(" log render sample Base ");
  }
  componentDidMount() {
    setTimeout(() => {
      this.rendereComplete();
    });
  }
}
