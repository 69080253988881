import {$rolesToValidationUrl, getAxios, rolesToValidation, rolesToValidationUrl, setLoading} from "./AuthActions";
import {
    ADDED_REQUESTS,
    ALL_PENDING_REQUESTS,
    API,
    CANCEL_HOLIDAY,
    CHILDREN_REQUESTS,
    ROLE_ADMIN_GROUP, 
    ROLE_ADMIN_COUNTRY,
    COUNT,
    DELETE_REQUEST,
    HR_REQUESTS,
    MY_REQUESTS,
    MY_REQUESTS_FOLLOW,
    MY_REQUESTS_HISTORY,
    PENDING_REQUESTS,
    PROCESSED_REQUESTS,
    TO_CANCEL_REQUESTS,
    TO_CANCEL_REQUESTS_ROLE_MAN
} from "../api/Endpoints";
import {store} from "../store/ConfigureStore";
import {showTimedToaster} from "./ReferencesActions";
import {getRecups} from "./HolidaysActions";
import { getToasterMessage } from "../utilities/MappingFunctions";
import { strings } from "../i18n/Strings";
import {hasAuthority, hasPermission} from "./AuthActions";

export function getMyRequestsDone(payload) {
    return {
        type: "GET_MY_REQUESTS",
        payload
    }
}

export function getProcessedRequestsDone(payload) {
    return {
        type: "GET_PROCESSED_REQUESTS",
        payload
    }
}

export function getMyRequestsHistoryDone(payload) {
    return {
        type: "GET_MY_REQUESTS_HISTORY",
        payload
    }
}

export function getMyRequestsFollowDone(payload) {
    return {
        type: "GET_MY_REQUESTS_FOLLOW",
        payload
    }
}

export function showRequestDetails(payload) {
    return {
        type: "SHOW_REQUEST_DETAILS",
        payload
    }
}

export function showRequestCancel(payload) {
    return {
        type: "SHOW_REQUEST_CANCEL",
        payload
    }
}

export function showRequestHistory(payload) {
    return {
        type: "SHOW_REQUEST_HISTORY",
        payload
    }
}

export function showConfirmationDialog(payload) {
    return {
        type: "SHOW_CONFIRMATION_DIALOG",
        payload
    }
}

export function getPendingRequestsDone(payload) {
    return {
        type: "GET_PENDING_REQUESTS",
        payload
    }
}

export function getAllPendingRequestsDone(payload) {
    return {
        type: "GET_ALL_PENDING_REQUESTS",
        payload
    }
}

export function getAddedRequestsDone(payload) {
    return {
        type: "GET_ADDED_REQUESTS",
        payload
    }
}

export function setRequestDetails(payload) {
    return {
        type: "SET_REQUEST_DETAILS",
        payload
    }
}

export function setRequestValidation(payload) {
    return {
        type: "SET_REQUEST_VALIDATION",
        payload
    }
}

export function getToCancelRequestsDone(payload) {
    return {
        type: "GET_TO_CANCEL_REQUESTS",
        payload
    }
}

export function getChildrenRequestsDone(payload) {
    return {
        type: "GET_CHILDREN_REQUESTS",
        payload
    }
}

export function getHRRequestsDone(payload) {
    return {
        type: "GET_HR_REQUESTS",
        payload
    }
}

export function setShowAddRequest(payload) {
    return {
        type: "SET_SHOW_ADD_REQUEST",
        payload
    }
}

export function getPendingRequestsCountDone(payload) {
    return {
        type: "GET_PENDING_REQUESTS_COUNT",
        payload
    }
}

export const processRequest = (registrationNumber, backup, requestId, comment, state, rejectionReason) => {
    return async dispatch => {
        const axios = getAxios();
        const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${requestId}`)
        const follow = myRequestsFollow.data.filter( step => step.state === "PENDING");
        const {role} = follow[0];
        const {profile} = store.getState().auth;
        const {_respProcessRequest} = strings;
        await axios.post(`${API}/conges/requests/process`, {
            "validatorEmpl": profile.userName,
            "backup": null,
            "validatorRole": role,
            "state": state,
            "rejectionReason": rejectionReason,
            "requestId": requestId,
            "comment": comment
        }).then(res => {
            dispatch(setRequestValidation({open: false, data: {}}));
            dispatch(getPendingRequests(0 ,25));
            dispatch(showTimedToaster({open: true, message: _respProcessRequest, type: "success"}));
            dispatch(getPendingRequestsCount());
        })
        .catch(err => {
            dispatch(setRequestValidation({open: false, data: {}}));
            dispatch(getPendingRequests(0 ,25));
            dispatch(getPendingRequestsCount());
            dispatch(showTimedToaster({open: true, message: getToasterMessage(err.response.data), type: "warning"}))
        });
        
    }
};



export const setRequestValidationDetails = (id) => {
    return async dispatch => {
        const {pendingRequests} = store.getState().requests;
        for (let request of pendingRequests.data) {
            if (id === request.id) {
                const {regestionNumberEmployee} = request;
                const axios = getAxios();
                const res = await axios.get(`${API}/conges/employee/project?user=${regestionNumberEmployee}`)
                dispatch(setRequestValidation({open: true, data: {...request, projectName: res.data.name}}))
                return request;
            }
        }
        return {};
    }
};

export const cancelRequest = (request) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respCancelRequest} = strings;
        axios.post(`${API}/${CANCEL_HOLIDAY}`, request)
            .then(res => {
                dispatch(getMyRequests(0, 25))
                dispatch(showConfirmationDialog({type: "CANCEL", id: null, open: false}))
                dispatch(showTimedToaster({open: true, message: _respCancelRequest, type: "success"}))
            })
            .catch(err => {
                console.log(err)
                dispatch(showTimedToaster({open: true, message: getToasterMessage(err.response.data), type: "warning"}))

            });
    }
};


export const deleteRequest = (requestId) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respDeleteRequestOK, _respDeleteRequestKO} = strings; 
        axios.get(`${API}/${DELETE_REQUEST}/${requestId}/status`)
            .then( res => {
                const status =res.data;
                if (!status) {
                    axios.put(`${API}/${DELETE_REQUEST}/${requestId}`)
                        .then(res => {
                            console.log(res)
                            dispatch(showConfirmationDialog({type: "DELETE", id: null, open: false}))
                            dispatch(getMyRequests(0, 25))
                            dispatch(showTimedToaster({open: true, message: _respDeleteRequestOK, type: "success"}))
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }
                else {
                    dispatch(showTimedToaster({open: true, message: _respDeleteRequestKO, type: "warning"}))
                }
            })


    }
};


export const deleteRequestRecup = (requestId) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respDeleteRequestRecupOK, _respDeleteRequestRecupKO} = strings; 
        axios.get(`${API}/${DELETE_REQUEST}/${requestId}/status`)
            .then( res => {
                const status =res.data;
                if (!status) {
                    axios.put(`${API}/${DELETE_REQUEST}/${requestId}`)
                        .then(res => {
                            dispatch(showConfirmationDialog({type: "DELETE", id: null, open: false}))
                            dispatch(getRecups(0, 25))
                            dispatch(showTimedToaster({open: true, message: _respDeleteRequestRecupOK, type: "success"}))
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }
                else {
                    dispatch(showTimedToaster({open: true, message: _respDeleteRequestRecupKO, type: "warning"}))
                }
            })


    }
};

function getCongesMapping(type) {
    const {_payedHoliday, _exceptionalHoliday,_withoutPayHoliday} = strings; 
    if (type === "PAIDHOLIDAY") {
        return _payedHoliday;
    }
    if (type === "EXCEPTIONALHOLIDAY") {
        return _exceptionalHoliday;
    } else {
        return _withoutPayHoliday;
    }
}

export const getMyRequests = (page, size, typeConge, typeRequest, returnDate, startDate, status) => {
    return async dispatch => {
        try {
            const axios = getAxios();
            const {requests} = store.getState();
            const {data, totalElements} = requests.myRequests;
            dispatch(getMyRequestsDone({loading: true, data, totalElements}));
            let myRequests = await axios.post(`${API}/${MY_REQUESTS}`, {
                "registrationNumber": null,
                "startDate": startDate,
                "endDate": returnDate,
                "status": status,
                "typeConge": typeConge,
                "sort": null,
                "order": false,
                "page": page,
                "size": size
            });
            dispatch(getMyRequestsDone({
                loading: false,
                data: myRequests.data.payload,
                totalElements: myRequests.data.totalElements
            }));
        } catch (error) {
            logError(error)
        }
    }
};

const logError = async error => {
    try {
        // await axios.post("http://localhost:3000/error-logger", error)
    } catch {
        console.log("LOG_SERVER_DOWN")
    }
}
const apiToCall = () =>{
    let apiUri;
    if (hasAuthority("ROLE_ADMIN_GROUP")){
      apiUri = `${ROLE_ADMIN_GROUP}`;
    } else if (hasAuthority("ROLE_ADMIN_COUNTRY")){
      apiUri = `${ROLE_ADMIN_COUNTRY}`;
    } else {
      apiUri = `${CHILDREN_REQUESTS}`;
    }
    return apiUri;
  }

export const getChildrenRequests = (page, size, addedForEmp, returnDate, startDate, status, typeConge) => {
    return async dispatch => {
        const axios = getAxios();
        const {requests} = store.getState();
        const {data, totalElements} = requests.childrenRequests;
        dispatch(getChildrenRequestsDone({loading: true, data, totalElements}));
        let childrenRequests = await axios.post(`${API}/`+apiToCall(), {
            "registrationNumber": addedForEmp,
            "startDate": startDate,
            "endDate": returnDate,
            "status": status,
            "typeConge": typeConge,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        });
        dispatch(getChildrenRequestsDone({
            loading: false,
            data: childrenRequests.data.payload,
            totalElements: childrenRequests.data.totalElements
        }));
    }
};

export const getHRRequests = (page, size, addedForEmp, returnDate, startDate, status, typeRequest, typeConge) => {
    return async dispatch => {
        const axios = getAxios();
        const {requests} = store.getState();
        const {data, totalElements} = requests.hrRequests;
        dispatch(getHRRequestsDone({loading: true, data, totalElements}));
        let hrRequests = await axios.post(`${API}/${HR_REQUESTS}`, {
                "status": status,
                "startDate": startDate,
                "endDate": returnDate,
                "typeRequest": typeRequest,
                "registrationNumber": addedForEmp,
                "typeConge": typeConge,
                "sort": null,
                "order": false,
                "page": page,
                "size": size
            }
        );
        dispatch(getHRRequestsDone({
            loading: false,
            data: hrRequests.data.payload,
            totalElements: hrRequests.data.totalElements
        }));
    }
};


export const getPendingRequests = (page, size, addedByEmp, typeConge, returnDate, startDate) => {
    return async dispatch => {
        const axios = getAxios();
        const {auth, requests} = store.getState();
        const {profile} = auth;
        const {data, totalElements} = requests.pendingRequests;
        dispatch(getPendingRequestsDone({loading: true, data, totalElements}));
        let pendingRequests = await axios.post(`${API}/${PENDING_REQUESTS}/${profile.userName}?${$rolesToValidationUrl()}`, {
            "registrationNumber": addedByEmp,
            "startDate": startDate,
            "returnDate": returnDate,
            "requestsToValidate": null,
            "status": null,
            "typeConge": typeConge,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        });
        dispatch(getPendingRequestsDone({
            loading: false,
            data: pendingRequests.data.payload,
            totalElements: pendingRequests.data.totalElements
        }));
    }
};

export const getAllPendingRequests = (page, size) => {
    return async dispatch => {
        const axios = getAxios();
        const {requests} = store.getState();
        const {data, totalElements} = requests.allPendingRequests;
        dispatch(getAllPendingRequestsDone({loading: true, data, totalElements}));
        let allPendingRequests = await axios.post(`${API}/${ALL_PENDING_REQUESTS}?role=MANAGER_VALIDATION&role=ADMIN_RH`, {
            "registrationNumber": null,
            "startDate": null,
            "returnDate": null,
            "requestsToValidate": null,
            "status": null,
            "typeConge": null,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        });
        dispatch(getAllPendingRequestsDone({
            loading: false,
            data: allPendingRequests.data.payload,
            totalElements: allPendingRequests.data.totalElements
        }));
    }
};

export const getToCancelRequests = (page, size, addedForEmp, typeConge, returnDate, startDate, status) => {
    return async dispatch => {
        const axios = getAxios();
        const {auth, requests} = store.getState();
        const {roleMan} = auth;
        const {data, totalElements} = requests.toCancelRequests;
        dispatch(getToCancelRequestsDone({loading: true, data, totalElements}));
        axios.post(`${API}/${roleMan ? TO_CANCEL_REQUESTS_ROLE_MAN : TO_CANCEL_REQUESTS}`, {
            "registrationNumber": addedForEmp,
            "startDate": startDate,
            "endDate": returnDate,
            "requestsToValidate": null,
            "status": status,
            "typeConge": typeConge,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        }).then ( res => {
            let toCancelRequests = res;
            dispatch(getToCancelRequestsDone({
                loading: false,
                data: toCancelRequests.data.payload,
                totalElements: toCancelRequests.data.totalElements
            }));
        }).catch(e => {
            dispatch(showTimedToaster({open: true, message: e.response.data.message, type: "warning"}));
        })
    }
};


export const getProcessedRequests = (page, size, addedForEmp, typeConge, typeRequest, endDate, startDate, state) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().requests.processedRequests;
        dispatch(getProcessedRequestsDone({loading: true, data, totalElements}));
        let processeRequests = await axios.post(`${API}/${PROCESSED_REQUESTS}`, {
            "registrationNumber": addedForEmp,
            "startDate": startDate,
            "endDate": endDate,
            "status": state,
            "typeConge": typeConge,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        });
        dispatch(getProcessedRequestsDone({
            loading: false,
            data: processeRequests.data.payload,
            totalElements: processeRequests.data.totalElements
        }));
    }
};
export const getAddedRequests = (page, size, addedForEmp, typeConge, typeRequest, returnDate, startDate, status) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().requests.addedRequests;
        dispatch(getAddedRequestsDone({loading: true, data, totalElements}));
        let addedRequests = await axios.post(`${API}/${ADDED_REQUESTS}`, {
            "registrationNumber": addedForEmp,
            "startDate": startDate,
            "endDate": returnDate,
            "requestsToValidate": null,
            "status": status,
            "typeConge": typeConge,
            "sort": null,
            "order": false,
            "page": page,
            "size": size
        });
        dispatch(getAddedRequestsDone({
            loading: false,
            data: addedRequests.data.payload,
            totalElements: addedRequests.data.totalElements
        }));

    }
};


export const getPendingRequestsCount = () => {
    return async dispatch => {
        const axios = getAxios();
        const id = store.getState().auth.profile.userName;
        const count = await axios.post(`${API}/${COUNT}/${id}?role=${rolesToValidation()}`)
        dispatch(getPendingRequestsCountDone(count.data))
    }
};

export const getMyRequestsHistory = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const myRequestsHistory = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${id}`)
        dispatch(getMyRequestsHistoryDone(myRequestsHistory.data))
    }
};

export const getToCancelRequestsHistory = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const toCancelRequestsHistory = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${id}`)
        dispatch(getMyRequestsHistoryDone(toCancelRequestsHistory.data))
    }
};

export const getToCancelRequestsFollow = (id) => {
    return async dispatch => {
        const toCancelRequests = store.getState().requests.toCancelRequests.data;
        for (let request of toCancelRequests) {
            if (request.id === id) {
                dispatch(getToCancelRequestsDone({
                    loading: false,
                    data: toCancelRequests,
                    details: request,
                    totalElements: toCancelRequests.length
                }));
                const {startDate, returnDate, updateDate, duration, typeConge, exceptionalType} = request;
                dispatch(setRequestDetails({startDate, returnDate, updateDate, duration, typeConge, exceptionalType}))
            }
        }
        const axios = getAxios();
        const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`);
        dispatch(getMyRequestsFollowDone(myRequestsFollow.data))
    }
};


export const getChildrenRequestsFollow = (id) => {
    return async dispatch => {
        const childrenRequests = store.getState().requests.childrenRequests.data;
        for (let request of childrenRequests) {
            if (request.id === id) {
                dispatch(getChildrenRequestsDone({
                    loading: false,
                    data: childrenRequests,
                    details: request,
                    totalElements: childrenRequests.length
                }));
                const {startDate, returnDate, updateDate, duration, typeConge, exceptionalType} = request;
                dispatch(setRequestDetails({startDate, returnDate, updateDate, duration, typeConge, exceptionalType}))
            }
        }
        const axios = getAxios();
        const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`);
        dispatch(getMyRequestsFollowDone(myRequestsFollow.data))
    }
};

export const getChildrenRequestsHistory = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const childrenRequestsHistory = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${id}`)
        dispatch(getMyRequestsHistoryDone(childrenRequestsHistory.data))
    }
};

export const getMyRequestsFollow = (id) => {
    return async dispatch => {
        const myRequests = store.getState().requests.myRequests.data;
        for (let request of myRequests) {
            if (request.id === id) {
                dispatch(getMyRequestsDone({
                    loading: false,
                    data: myRequests,
                    details: request,
                    totalElements: myRequests.length
                }));
                const {startDate, returnDate, updateDate, duration, typeConge} = request;
                dispatch(setRequestDetails({startDate, returnDate, updateDate, duration, typeConge}))
            }
        }
        const axios = getAxios();
        const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`);
        dispatch(getMyRequestsFollowDone(myRequestsFollow.data))
    }
};


export const getProcessedRequestsHistory = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const myRequestsHistory = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${id}`)
        dispatch(getMyRequestsHistoryDone(myRequestsHistory.data))
    }
};

export const getProcessedRequestsFollow = (id) => {
    return async dispatch => {
        const processedRequests = store.getState().requests.processedRequests.data;
        const $processedRequests = processedRequests.map(request => request.assignedToConge)
        for (let request of $processedRequests) {
            if (request.id === id) {
                dispatch(getProcessedRequestsDone({
                    loading: false,
                    data: processedRequests,
                    details: request,
                    totalElements: processedRequests.length
                }));
                const {startDate, returnDate, updateDate, totalDuration, typeConge} = request;
                dispatch(setRequestDetails({
                    startDate,
                    returnDate,
                    updateDate,
                    duration: totalDuration,
                    typeConge: getCongesMapping(typeConge)
                }))
            }
        }
        const axios = getAxios();
        const processedRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`);
        dispatch(getMyRequestsFollowDone(processedRequestsFollow.data))
    }
};

export const getAddedRequestsHistory = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const addedRequestsHistory = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${id}`)
        dispatch(getMyRequestsHistoryDone(addedRequestsHistory.data))
    }
};


export const getAddedRequestsFollow = (id) => {
    return async dispatch => {
        const addedRequests = store.getState().requests.addedRequests.data;
        const $addedRequests = addedRequests.map(request => request.assignedToConge)
        for (let request of $addedRequests) {
            if (request.id === id) {
                dispatch(getAddedRequestsDone({
                    loading: false,
                    data: addedRequests,
                    details: request,
                    totalElements: addedRequests.length
                }));
                const {startDate, returnDate, updateDate, totalDuration, typeConge} = request;
                dispatch(setRequestDetails({
                    startDate,
                    returnDate,
                    updateDate,
                    duration: totalDuration,
                    typeConge: getCongesMapping(typeConge)
                }))
            }
        }
        const axios = getAxios();
        const addedRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`);
        dispatch(getMyRequestsFollowDone(addedRequestsFollow.data))
    }
};
