import React, {Component, useEffect, useState} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {setShowAddJustification} from "./actions/AbsenceActions";
import Sidebar from "./components/sidebar";
import Home from "./pages/home";
import PresentPage from "./pages/present";
import Loader from "./components/loader";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { loadCldr} from '@syncfusion/ej2-base';
import {getProjects,getTeamsBackup} from "./actions/planningActions";
import {getFromSession} from "./actions/AuthActions";
import PresentLoader from "./components/loaders/presentLoader";

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-CH/numbers.json'),
    require('cldr-data/main/fr-CH/timeZoneNames.json')
);
class App extends React.Component {

    constructor(props) {
        super(props);

        //getTeamsBackup();
        this.state = {
            currentUser: null,
            loading:true
        };
    }
    async componentDidUpdate(prevPrope) {
        let profile = this.props.auth.profile;
        if (profile !== prevPrope.auth.profile && profile.userName !== "") {
            let currentUser = this.props.auth.profile;
            //let currentUser = JSON.parse( getFromSession("INTELCIA_OAUTH_REMOTE_USER"));
            console.log("currentUser in app : ", currentUser);
            await this.props.getProjects(currentUser.userName, currentUser.job);
            this.setState(prevState => ({
                currentUser: currentUser,
                loading: false
            }));

        }

    }

    render() {
        const {isLoggedIn, loading} = this.props.auth;
        const {count} = this.props.requests;
        return (
                    <>
                 <Router>
                     {this.state.loading ? <PresentLoader /> : [
                    <Sidebar currentUser={this.state.currentUser} />,
                    <Switch>
                    <Route exact path="/" component={PresentPage} />
                    <Route path="/present" component={PresentPage} />
                    </Switch>
                     ]}
                </Router>
                </>
        );
    }
}

function mapStateToProps(state) {
    return {auth: state.auth, absence: state.absence, requests: state.requests};
}

const mapDispatchToProps = {
    getProjects
}
const mapActionsToProps = {
    setShowAddJustification: setShowAddJustification
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
