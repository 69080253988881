import React from 'react';
import Switch from '@material-ui/core/Switch';
import { useIosSwitchStyles } from '@mui-treasury/styles/switch/ios';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const IosSwitchStyle = ({setSwitchTo}) => {
    const [toggled, setToggled] = React.useState(false);
    const iosStyles = useIosSwitchStyles();


    const toggleChecked = (e) => {
        console.log("toggle checked");
        setSwitchTo(true);
        let checked = e.target.checked;
        checked ? setSwitchTo(true) : setSwitchTo(false);
        setToggled(checked)
    };
    return (
        <div>
            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Team Planner</Grid>
                    <Grid item>
                        <Switch
                            classes={iosStyles}
                            checked={toggled}
                            onChange={toggleChecked}
                        />
                    </Grid>
                    <Grid item>Planning TO-myCongés</Grid>
                </Grid>
            </Typography>

        </div>
    );
};

export default IosSwitchStyle;
