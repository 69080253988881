export default function hrExports(state = {
    inErrorExports: {
        loading: false,
        totalElements: 0,
        data: []
    },
    unExported: {
        loading: false,
        totalElements: 0,
        data: []
    }
}, {type, payload}) {
    switch (type) {
        case "GET_IN_ERROR_EXPORTS":
            return {...state, inErrorExports: payload};
        case "GET_UNEXPORTED":
            return {...state, unExported: payload};
        default:
            return state
    }
}


