import React, { useState, useEffect } from "react";
import DataList from "../components/list";
import { DepartmentData, Users } from "../components/data";
import * as FaIcons from "react-icons/fa";
import TimelineView from "../components/timelinescheduler/timelineview";
import UserDetails from "../components/userDetails";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import PuffLoader from "../components/puffloader";
import imgGrp from "../assets/grp.png";
import IosSwitchStyle from "../components/utilities/iosSwitch";
import TimelineDash from "../components/dashboard/TimelineDash";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {getPlanningsDash} from "../actions/DashboardActions";

import {
  createPlanning,
  deletePlanning,
  editPlanning,
  getPlannings,
  getExternalPlannings,
  getProjects,
  getTeamsBackup,
  getUsers, hideLoader, hideLoaderSpinner, showLoaderSpinner
} from "../actions/planningActions";
import { connect, useDispatch } from "react-redux";
import {getFromSession} from "../actions/AuthActions";
import PresentLoader from "../components/loaders/presentLoader";
import Grow from "@material-ui/core/Grow";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {CheckBoxComponent} from "@syncfusion/ej2-react-buttons";
import ComboBox from "../components/utilities/ComboBox";
import {store} from "../store/ConfigureStore";
import list from "../components/list";
import Tooltip from "@material-ui/core/Tooltip";


const PresentPage = (props) => {
  const {project} = props;
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [user, setUser] = useState();
  const [sites, setSites] = useState();
  const dispatch = useDispatch();
  const [equipes, setEquipes] = useState(
    //{ name: "MU-PHX-SFRC FTT N1", img: imgGrp },
  );
  const [currentTeam, setCurrentTeam] = useState();
  const [site, setSite] = useState();
  const [plannings, setPlannings] = useState(false);
  const [externalPlannings, setExternalPlannings] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [listSelectedAgents,setListSelectedAgents] = useState([]);
  const [listObjectsSelectedAgents,setListObjectsSelectedAgents] = useState([]);
  const [listSelectedEquipes,setListSelectedEquipes] = useState();
  const [state, setState] = useState({
    title: "Projet(s)",
    data:[],
    icon: <FaIcons.FaAngleRight />,
    level:"site"
  });
  const [test,setTest] = useState();
  const [switchTo,setSwitchTo] = useState();
  const [comboEquipe,setComboEquipe] = useState();
  const [fields, setFields] = useState({ text: 'teamName', value: 'teamsId' });
  const [searchedAgent, setSearchedAgent] = useState();

  useEffect(() => {
    console.log("test inside present switchTo",switchTo);
  },[switchTo])

  useEffect(() => {
    console.log("test inside present",test);
  },[test,setTest])

    const [currentUser,setCurrentUser] = useState(false);
    const [date,setDate] = useState(new Date());
    useEffect(() => {
        console.log("current user in present : ",JSON.parse(getFromSession("INTELCIA_OAUTH_REMOTE_USER")));
        setCurrentUser(JSON.parse(getFromSession("INTELCIA_OAUTH_REMOTE_USER")));
    },[])

  useEffect(() => {
    console.log("current date in present : " , date);
    },[date]);

  useEffect( async  () => {
    let currentUser = await JSON.parse(getFromSession("INTELCIA_OAUTH_REMOTE_USER"))
    console.log("current user in present2 : ",currentUser);
    dispatch(showLoaderSpinner());
    currentUser && await dispatch(getProjects(currentUser.userName,currentUser.job));
    dispatch(hideLoaderSpinner())
    //dispatch(getTeamsBackup());
    setEquipes(props.teams);
    setSites(props.teams);
    setPlannings(props.plannings);
    setExternalPlannings(props.externalPlannings);

    console.log("plannings ==> " , plannings);
    console.log("externalPlannings ==> " , externalPlannings);
  },[])

  useEffect(() => {
    console.log("Sites ==> " , props.teams);
    setSites(props.teams);
    setState( {
      title: "Projet(s)",
      data:props.teams ? props.teams :[]  ,
      icon: <FaIcons.FaAngleRight />,
      level:"site"
    });
  }, [props.teams]);

  useEffect(() => {

    let arrayEquipe = [];
    for (let i = 0 ; i < props.teams.length;i++){
      arrayEquipe.push(...props.teams[i].teamPlanningVOS)
    }
    console.log("comboEquipe ==> " , arrayEquipe);
    setComboEquipe(arrayEquipe);
  }, [props.teams]);

  useEffect(() => {
      console.log("Equipes Backup ==> " , props.teamsBackup);
  }, []);

  const handleShowEquipes = (equipes) => {
    //console.log("userrssss",users);

    var listofobject = [] ;

    let    usersFiltered =[];
    console.log("handleshowequipe : ",equipes);

    equipes &&  equipes.map(teams => listofobject.push(teams.teamPlanningVOS));
    //usersFiltered = listofobject.filter(list => props.auth.profile.userName !== list.registrationNumber);
    console.log("usersFiltered",listofobject);
    setState({
      title: "Equipe(s)",
      data: props.teams ? listofobject[0] : [],
      icon: <FaIcons.FaAngleRight/>,
      level: "equipe"
    });
    //setShowScheduler(true);
    //setShowLoader(true);
  }

  const handleShowSites = () => {

    setState({
      title: "Projet(s)",
      data: props.teams ? props.teams : [],
      icon: <FaIcons.FaAngleRight/>,
      level:"site"
    });
  }

  const handleBackFromUsers = () => {
    let equiposs = [];
    console.log("handlebackfromusers currentTeam : ",currentTeam);
    equiposs.push(site);
    console.log("handlebackfromusers2 : ",equipes);
    setShowUserDetails(false);
    setShowScheduler(false);
    //setCurrentTeam(currentTeam);
    let currentTeamTab = [];
    currentTeamTab.push(currentTeam);
    handleShowUsers(currentTeamTab);
    setUser();
    //setEquipes();
    //setCurrentTeam(currentTeam);
  };

  const handleBackToTeams = () => {
    let siteTab = [];
    siteTab.push(site);
    handleShowEquipes(siteTab);
    setUser();
    setSites(site);
    setEquipes();
    setCurrentTeam();
    setDate(new Date());
    setShowScheduler(false);
    setShowUserDetails(false);
  };



  const handleShowUsers = (users) =>{
      console.log("userrssss",users);

      var listofobject = [] ;

      let    usersFiltered =[];

      users &&  users.map(teams => listofobject.push(teams.employeeVOS));



      usersFiltered = listofobject.filter(list => props.auth.profile.userName !== list.registrationNumber);
      console.log("usersFiltered",usersFiltered);
    setState({
      title: "Membres de l’équipe",
      data:  props.teams ?  usersFiltered[0]:[] ,
      icon: <FaIcons.FaUserAlt />,
      level: "membres"
    });
    //setEquipes();
    setSites();
    setShowScheduler(true);
    //setShowLoader(true);
  }

  const handleShowSearchedAgent = (searchedAgent) => {

    let searchedAgentTab = [];
    searchedAgentTab.push(searchedAgent);
    setState({
      title: "Résultat de la recherche",
      data:  searchedAgent ? searchedAgentTab :  [],
      icon: <FaIcons.FaUserAlt />,
      level: "search"
    });
    console.log("handleShowSearchedAgent",searchedAgent);
  }


  console.log("USER  = ", user);
  console.log("EQUIPES  = ", equipes);
  console.log("listSelectedAgents in present  = ", listSelectedAgents);
  console.log("Teams ", props.teams);
  console.log("Plannings : ", props.plannings);
  useEffect(()=> {
    console.log("listSelectedAgents in present useeffect  = ", listSelectedAgents);
  },[listSelectedAgents])

  useEffect(()=> {
    console.log("searchedAgent in present useeffect  = ", searchedAgent);
    handleShowSearchedAgent(searchedAgent);
  },[searchedAgent]);

  const onChange = async (value) => {
    console.log("onChange props : ",value);
    await props.getPlanningsDash(value.itemData.teamsId);
    //setSelectedEquipe(value.itemData);
    //console.log("selecter equipe : ", selectedEquipe);
    console.log("planningsDash after onChange : ",props.planningsDash);
    console.log("planningsDash after onChange props.itemData.teamsId : ",value.itemData.teamsId);
  }

  if(props.loading){
    return <PresentLoader />
  }
  if (showLoader) {
    return <PuffLoader setShowLoader={setShowLoader} />;
  }

  else
    return (
      <Grid container>
        <Grid item xs={12} sm={3}>
          <div style={{background:"#8080801f"}}>
            <div style={{padding: '5px 10px', marginLeft: '10px'}}>
              <ComboBox teams={props.teams} searchedAgent={searchedAgent} setSearchedAgent={setSearchedAgent} />
            </div>
            <DataList
              setShowLoader={setShowLoader}
              title={state.title}
              level={state.level}
              data={state.data}
              icon={state.icon}
              handleShowUsers={handleShowUsers}
              setShowUserDetails={setShowUserDetails}
              setUser={setUser}
              user={user}
              handleShowEquipes={handleShowEquipes}
              handleShowSites={handleShowSites}
              handleBackFromUsers={handleBackFromUsers}
              handleBackToTeams={handleBackToTeams}
              setShowScheduler={setShowScheduler}
              setEquipes={setEquipes}
              setSite={setSite}
              setCurrentTeam={setCurrentTeam}
              currentTeam={currentTeam}
              equipes={equipes}
              setSites={setSites}
              sites={sites}
              getPlannings={getPlannings}
              getExternalPlannings={getExternalPlannings}
              setListSelectedAgents={setListSelectedAgents}
              setListObjectsSelectedAgents={setListObjectsSelectedAgents}
              listObjectsSelectedAgents={listObjectsSelectedAgents}
              setListSelectedEquipes={setListSelectedEquipes}
              dispatch={dispatch}
              date={date}
              listSelectedAgents={listSelectedAgents}
              test={test}
              setTest={setTest}
              setDate={setDate}
            />
          </div>
        </Grid>

        {(showScheduler || showUserDetails) ? (
          <Grid
            justify="center"
            style={{ padding: "0.2rem 1rem" }}
            item
            xs={12}
            sm={9}
          >
            <Grid
              style={{ marginBottom: "0.2rem" }}
              justify="space-around"
              alignItems="center"
              className="item-center"
              item
              xs={12}
              sm={12}
            >

              {user!=null &&
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar style={{ margin: "0 0.5rem" }}src={"https://mscore.intelcia.com/picture/default/"+user.userName}></Avatar>
                    <p> {user.fullName + " " + user.userName}  </p>
                    <div style={{ marginLeft: "5rem" , maxWidth: '400px', minWidth: '350px'}}> <IosSwitchStyle setSwitchTo={setSwitchTo} /> </div>
              </div>
              }
              {equipes != null && equipes.teamName && (!listSelectedAgents || listSelectedAgents.length === 0 ) &&
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar src={imgGrp} style={{ margin: "0 0.5rem" }} />
                <p> {equipes.teamName}</p>
              </div>
              }

              {sites!=null && sites.siteName &&
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar src={imgGrp} style={{ margin: "0 0.5rem" }} />
                <p> {sites.siteName}</p>
              </div>
              }

              {listObjectsSelectedAgents !=null && listObjectsSelectedAgents !== [] && listObjectsSelectedAgents.map((item, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>

                <Tooltip title={item.fullname} interactive>
                  <Avatar src={"https://mscore.intelcia.com/picture/default/"+item.id} />
                </Tooltip>
              </div>
              ))
              }
            </Grid>


            {showScheduler &&
            <TimelineView
                user={user}
                plannings={props.plannings}
                externalPlannings={props.externalPlannings}
                equipes={props.teams}
                currentTeam={currentTeam}
                listSelectedAgents={listSelectedAgents}
                setListSelectedAgents={setListSelectedAgents}
                setListObjectsSelectedAgents={setListObjectsSelectedAgents}
                listSelectedEquipes={listSelectedEquipes}
                currentSite={site}
                currentUser={currentUser}
                setDate={setDate}
                switchTo={switchTo}
                setSwitchTo={setSwitchTo}
            />
            }
          </Grid>
            ) :
              <Grid
                  justify="center"
                  style={{ padding: "0.2rem 1rem" }}
                  item
                  xs={12}
                  sm={9}
              >
                <Grid
                    style={{ marginBottom: "0.2rem" }}
                    justify="space-around"
                    alignItems="center"
                    className="item-center"
                    item
                    xs={12}
                    sm={12}
                >
                  <div style={{minWidth: '200px' ,maxWidth: '200px'}}>
                    <DropDownListComponent id="ddlelement" dataSource={comboEquipe ?
                        comboEquipe : []} fields={fields}
                        placeholder="Selectionner une équipe"
                        change={onChange} />
                  </div>
                </Grid>
                <TimelineDash
                    teams={props.teams}
                />
              </Grid>
        }
        {console.log("showLoader = ", showLoader)}
      </Grid>
    );
};

function mapStateToProps(state) {
  return {
    teams: state.project.teams,
    teamsBackup: state.project.teamsBackup,
    plannings:state.project.plannings,
    externalPlannings:state.project.externalPlannings,
    project: state.project,
    auth:state.auth,
    loading:state.project.loading,
    planningsDash:state.dashboard.planningsDash
  }
}

export default connect(mapStateToProps,{getPlanningsDash})(PresentPage);
