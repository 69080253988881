import React, {useEffect, useState} from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./sidebarData";
import {getFromSession, logout} from "../actions/AuthActions";
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from "@material-ui/core/Avatar";

import "./sidebar.css";
import {IconButton} from "@material-ui/core";

const Sidebar = ({currentUser}) => {
    useEffect(() => {
        console.log("current user : ",currentUser);
        //setCurrentUser(JSON.parse(getFromSession("INTELCIA_OAUTH_REMOTE_USER")));
    },[])
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const handleClick = (e) => {
    console.log("In handle Click e = ", e.target.textContent);
  };
  

  return (
    <>
      <div className="navbar">
          {/*<Link to="#" className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </Link>*/}
        <div
          style={{
            marginLeft: "50px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>

              <img src={require('../assets/myteam.png')}  alt="MyTeam PLANNER"  width="160" height="70" />


          </div>
          <div style={{
              display: "flex",
              alignItems: "center",
          }}>
              <Tooltip title={currentUser.firstName+" "+currentUser.lastName} interactive>
                 <Avatar style={{ margin: "0 0.5rem" }}src={"https://mscore.intelcia.com/picture/default/"+currentUser.userName} ></Avatar>
              </Tooltip>
            <FaIcons.FaSignOutAlt
              style={{
                color: "white",
                fontSize: "1.5rem",
                margin: "0 0.5rem ",
              }}
              onClick={() => {logout()}}
            />
          </div>
        </div>
      </div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
            </Link>
          </li>
          {SidebarData.map((item, index) => (
            <li key={index} className={item.cName} onClick={handleClick}>
              <Link to={item.path}>
                {item.icon} <span className="title">{item.title} </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
