import moment from 'moment-timezone';
import {store} from "../store/ConfigureStore";

const timeZoneMappings = {
    'MA': "Etc/GMT-1",
    'FR': "Etc/GMT-1",
    'SN': "Etc/GMT-1",
    'CI': "Etc/GMT-1",
    'MU': "Etc/GMT-1",
    'MG': "Etc/GMT+2",
    'PT': "Etc/GMT-1"
}
const momentSetTimeZone = () =>  {
    const countryCode = store.getState().auth.profile;
    moment.tz.setDefault("Etc/GMT-1");
    console.log(moment())
}
export default momentSetTimeZone;
