import React, {useEffect, useState} from 'react';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';

export default function ComboBox({teams,setSearchedAgent}) {
    const [agents, setAgents] = useState();

    useEffect(() => {
        console.log("ComboBox teams : ",teams);
        let arrayEquipe = [];
        for (let i = 0 ; i < teams.length;i++){
            arrayEquipe.push(...teams[i].teamPlanningVOS)
        }
        let equipes = arrayEquipe;
        console.log("ComboBox equipes : ",equipes);
        let arrayAgents = [];
        for(let i =0;i< equipes.length;i++){
            console.log(equipes[i].employeeVOS);
            arrayAgents.push(...equipes[i].employeeVOS);
        }
        console.log("arrayAgents = ", arrayAgents);
        let agentsNorm = [];
        for(let i = 0; i<arrayAgents.length;i++){
            let obj = {userName:arrayAgents[i].userName,text:arrayAgents[i].fullName+" "+arrayAgents[i].userName,fullName:arrayAgents[i].fullName};
            agentsNorm.push(obj);
        }
        console.log("agentsNorm = ",agentsNorm);
        setAgents(agentsNorm);
    },[teams]);

    const onChange = (params) => {
        console.log("params onChange : ",params.itemData);
        setSearchedAgent(params.itemData);
    }

    const noRecordsTemplate = (params) => {
        return (
            <span className='norecord'> Aucun résultat trouvé</span>
        );
    }
    return (
        <AutoCompleteComponent
            id="atcelement"
            noRecordsTemplate={noRecordsTemplate}
            dataSource={agents}
            fields={fields}
            placeholder="Rechercher..."
            highlight={true}
            select={onChange}
        />
    );
}
const fields = { value: 'text'};
