import {
    getAxios,
    isLoggedIn,
    getFromSession,
} from './AuthActions';
import {
    store
} from "../store/ConfigureStore";
import {
    API,
    ESTIMATED,
    HR,
    SOLDE_DES_EMPLOYES
} from "../api/Endpoints";
import exportToExcel from './ExportToExcel';

export function getEmployeeSoldeSuccess(payload) {
    return {
        type: "GET_EMPLOYEE_SOLDE",
        payload
    }
}

export function getTotalEmployeeSoldeSuccess(payload) {
    return {
        type: "GET_TOTAL_EMPLOYEE_SOLDE",
        payload
    }
}

export function loadingEmployeeSolde(payload) {
    return {
        type: "LOADING_EMPLOYEE_SOLDE",
        payload
    }
}

export function getSoldeSuccess(payload) {
    return {
        type: "GET_SOLDE",
        payload
    }
}

export function getTotalDaysOff(payload) {
    return {
        type: "GET_TOTAL_DAYSOFF",
        payload
    }
}

export const getSolde = () => {
    return async dispatch => {
        try {
            const axios = getAxios();
            const {
                profile,
            } = store.getState().auth;
            const configUser = JSON.parse(getFromSession('configUser'));
            // const {configUser} = store.getState().references;
            const hr = await axios.get(`${API}/${HR}/${profile.userName}`);
            const estimated = await axios.get(`${API}/${ESTIMATED}/${profile.userName}`);
            // const percentage = await axios.get(`${API}/${PERCENTAGE}/${profile.userName}`);
            const recuperation = await axios.get(`${API}/conges/solde/recup/${profile.userName}`);
            let rtt, seniority, estimatedRtt, estimatedSeniority = null;
            if(configUser && configUser.rttBalance){
            rtt = await axios.get(`${API}/conges/solde/rtt/${profile.userName}`);
            seniority = await axios.get(`${API}/conges/solde/seniority/${profile.userName}`);
            estimatedRtt = await axios.get(`${API}/conges/solde/estimatedRtt/${profile.userName}`);
            estimatedSeniority = await axios.get(`${API}/conges/solde/estimatedSeniority/${profile.userName}`);
            }
            dispatch(getSoldeSuccess( rtt && seniority ? {
                hr: hr.data == -9999 ? 0 : hr.data,
                estimated: estimated.data,
                recuperation: recuperation.data,
                rtt: rtt.data || 0,
                anciennete: seniority.data || 0,
                pendingRtt: estimatedRtt.data || 0,
                pendingAnciennete: estimatedSeniority.data || 0
            } : {
                hr: hr.data == -9999 ? 0 : hr.data,
                estimated: estimated.data,
                recuperation: recuperation.data,
            }));
            dispatch(isLoggedIn(true));
        } catch (e) {
            /*
             * if can't establish connection to backend set logged out status to prevent user
             * from seeing empty result home screen
             * */
            dispatch(isLoggedIn(false));
        }
    }
};

export const getEmployeeSolde = (page, size) => {
    return async dispatch => {
        if (page == 0) {
            dispatch(getEmployeeSoldeSuccess([]));
        }
        const axios = getAxios();
        dispatch(loadingEmployeeSolde(true));
        const {
            auth,
            solde
        } = store.getState();

        const {
            profile
        } = auth;
        const {
            authorities
        } = profile;
        const congesAuthorities = authorities.filter(item => item.clientId === "conges").map(item => item.authority);
        const soldeEmployee = await axios.post(`${API}/${SOLDE_DES_EMPLOYES}`, {
            "matricule": null,
            "uo": null,
            "firstName":  null,
            "lastName":  null,
            "order":false,
            "sort": "solde",
            "solde":null,
            "currentUser": profile.userName,
            "authorities": congesAuthorities,
            page,
            size
        });
        dispatch(getTotalEmployeeSoldeSuccess(soldeEmployee.data.totalElements));
        dispatch(getEmployeeSoldeSuccess([...solde.soldeEmployee, ...soldeEmployee.data.payload]));
        dispatch(loadingEmployeeSolde(false));
    }
};
export const getEmployeeSoldeByPage = (page, size, sort, order, matricule, uo, firstName, lastName, solde) => {
    return async dispatch => {
        console.log('---------------------> getEmployeeSoldeByPage', page, size, sort, order);
        const axios = getAxios();
        dispatch(loadingEmployeeSolde(true));
        const { auth } = store.getState();
        const { profile } = auth;
        const { authorities } = profile;
        const congesAuthorities = authorities.filter(item => item.clientId === "conges").map(item => item.authority);
        
        if (page == undefined && size == undefined) {
            const soldeEmployee = await axios.post(`${API}/${SOLDE_DES_EMPLOYES}`, {
                matricule,
                uo,
                firstName,
                lastName,
                solde,
                sort,
                order,
                "currentUser": profile.userName,
                "authorities": congesAuthorities,
                "page": null
            });
            let data = soldeEmployee.data.payload.map(s => {
                const {registrationNumber, firstName, lastName, solde, uo} = s;
                return {Matricule:registrationNumber, Prenom:firstName, Nom:lastName, Solde:solde, UO:uo};
            });
            
            setTimeout(() => {
                exportToExcel(data);
            }, 1000);
        } else {
            const soldeEmployee = await axios.post(`${API}/${SOLDE_DES_EMPLOYES}`, {
                matricule,
                uo,
                firstName,
                lastName,
                solde,
                order,
                "sort": sort ? sort: "solde",
                "currentUser": profile.userName,
                "authorities": congesAuthorities,
                page,
                size
            });
            dispatch(getTotalEmployeeSoldeSuccess(soldeEmployee.data.totalElements));
            dispatch(getEmployeeSoldeSuccess(soldeEmployee.data.payload));
            dispatch(loadingEmployeeSolde(false));
        }
    }
};