
import {getAxios} from "./AuthActions";
import {
    GET_PLANNINGS_DASH
} from "./types";

export const getPlanningsDash = (teamId) => async dispatch => {
    try {
        const axios = getAxios();
        const res = await axios.get("https://myteamplanner.intelcia.com/api/default/team?teamId="+teamId+"&externalPlanning=false");
        console.log("result in getPlanningsDash : ",res.data);

        dispatch( {
            type: GET_PLANNINGS_DASH,
            payload: res.data
        })
    } catch (err) {
        console.log("erreur in get plannings :",err);
    }
};

