import {
    GET_PLANNINGS_DASH,
} from "../actions/types";

const initialState = {
    projects: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_PLANNINGS_DASH:
            console.log("in reducer GET_PLANNINGS_DASH :  ",action.payload);
            return {
                ...state,
                planningsDash: action.payload
            };

        default:
            return state;
    }
}
