import {
  GET_PROJECTS,
  GET_PROJECT,
  DELETE_PROJECT,
  SAVE_PROJECT,
  GET_PLANNING,
  GET_BACKUP,
  SHOW_LOADER,
  HIDE_LOADER,
  GET_AVAILABILITY, GET_EXTERNAL_PLANNING, SELECT_MEMBERS
} from "../actions/types";

const initialState = {
  projects: [],
  membersSelected: [],
  project: {},
  loading:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        teams: action.payload.data
      };

      case GET_BACKUP:
        console.log("action.payload.data",action.payload.data[0]);
      return {
        ...state,
        teamsBackup: action.payload.data[0]
      };

    case GET_PROJECT:
      return {
        ...state,
        project: action.payload
      };

    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(
          project => project.projectIdentifier !== action.payload
        )
      };

      case SAVE_PROJECT:
      return {
        ...state,
      };

    case GET_PLANNING:
      console.log("getPlannnings in case : ",action.payload);
      return {
        ...state,
        plannings: action.payload
      };

      case GET_EXTERNAL_PLANNING:
      console.log("getExternalPlannnings in case : ",action.payload);
      return {
        ...state,
        externalPlannings: action.payload
      };

      case GET_AVAILABILITY:
      console.log("getAVAILABILITY in case : ",action.payload);
      return {
        ...state,
        availabilities: action.payload
      };

      case SHOW_LOADER:
      return {
        ...state,
        loading: true
      };

      case HIDE_LOADER:
      return {
        ...state,
        loading: false
      };

    case SELECT_MEMBERS:
      return {
        ...state,
        membersSelected: action.payload
      };
    default:
      return state;
  }
}
