const apiMapping = {
    "prod": "https://app-planning-manager.intelcia.com",
    "preprod": "http://app-planning-manager.intelcia.com",
    // "localDev": "http://172.19.44.15:8081",
    "localDev": "http://172.19.104.30:8081",

};
// export const API = apiMapping["localDev"];
export const API = apiMapping["prod"];


export const CORE_API = "https://mscore.intelcia.com";
export const DEAFULT_AVATAR_CORE_SERVER = "picture/default";
export const MEDIUM_AVATAR_CORE_SERVER = "picture/medium";
export const EXCEPT_TYPE_RH = "conges/exceptionalType/exceptTypeHr";
export const EXCEPT_TYPE_RH_USER = "conges/exceptionalType/exceptTypeHrUser";
export const BACKUP = "conges/employee/backup";
export const DAYS_OF = "conges/myCongeDate/daysOff";
export const HR = "conges/solde/hr";
export const ESTIMATED = "conges/solde/estimated";
export const PERCENTAGE = "conges/solde/percentage";
export const TIMELINE = "conges/timeLine";
export const MY_UO = "conges/org/uo";
export const MY_REQUESTS = "conges/request/filter";
export const MY_REQUESTS_HISTORY = "conges/requests/details";
export const CONFIG_USER = "conges/config";
export const MY_REQUESTS_FOLLOW = "conges/requests";
export const DELETE_REQUEST = "conges/request";
export const SOLDE_DES_EMPLOYES = "conges/solde/soldeDesEmployees";
export const PROCESSED_REQUESTS = "conges/requests/processed";
export const PENDING_REQUESTS = "conges/requests/pending";
export const IS_PROD = "conges/employee/isProd";
export const IS_TL = "conges/employee/isTl";
export const CHILDREN_REQUESTS = "conges/request/filter/childrenRequests";
export const ROLE_ADMIN_GROUP = "conges/request/filter/groupRequests";
export const ROLE_ADMIN_COUNTRY = "conges/request/filter/countryRequests";
export const ALL_PENDING_REQUESTS = "conges/task";
export const TO_CANCEL_REQUESTS = "conges/cancelHoliday/requestsToCancel";
export const TO_CANCEL_REQUESTS_ROLE_MAN = "conges/cancelHoliday/requestsManToCancel";
export const CANCEL_HOLIDAY = "conges/cancelHoliday";
export const HR_REQUESTS = "conges/request/hrRequests";
export const MAPPING_TELEOPTI = "conges/exceptionalType/filterTeleopti";
export const REJECTION_MOTIFS = "conges/rejectionReason/filter";
export const EXCEPT_HOLIDAY = "/conges/exceptionalType/filter";
export const RECUPS = "conges/recups/filter";
export const BY_DAY_ABSENCE = "conges/abscence/dayAbsence";
export const BY_HOUR_ABSENCE = "conges/abscence/hourAbsence";
export const ADDED_REQUESTS = "conges/requests/added";
export const PUBLIC_HOIDAYS = "conges/publicHolidays/filter";
export const EMPLOYEE_CHILDREN = "conges/employee/children";
export const EMPLOYEE_CHILDREN_COUNTRY = "conges/employee/children/country";
export const JUSTIFY_HOUR = "conges/abscence/justifyHour";
export const ADD_EXCEP_CONGE_REQUEST = "conges/excepCongeRequest";
export const ADD_PAYED_CONGE_REQUEST = "conges/requestProcess";
export const GET_CONFIG_REQUEST = "conges/requestProcess";
export const COUNT = "conges/requests/pending/count";
export const CANCEL_HOLIDAY_RH = "conges/cancelHoliday/cancelHolidaysRH";
export const EXCEPTIONAL_TYPES = "conges/exceptionalType/exceptTypeHr?user=";
export const VALIDATED = "conges/requests/validated";
export const FLAGS_CDN = "https://www.countryflags.io";
export const OAUTH_LOGOUT_DEV = "http://oauth.intelcia.com/uaa/logout";
export const OAUTH_LOGOUT_PROD = "https://oauth.intelcia.com/uaa/logout";



