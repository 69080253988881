import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Month} from '@syncfusion/ej2-schedule';
import { TimelineViews, TimelineMonth, ScheduleComponent, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, Inject } from '@syncfusion/ej2-react-schedule';
import { resourceData } from './datasource';
import {teams,data} from './datasource2';
import { extend } from '@syncfusion/ej2-base';
import {connect} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import imgGrp from "../../assets/grp.png";
import {getPlanningsDash} from "../../actions/DashboardActions";
class TimelineDash extends React.Component {
    constructor() {
        super(...arguments);
        this.datasource = extend([], teams, null, true);
        console.log("datasource2 : ",this.datasource);
        this.data = extend([], data, null, true);
        this.teams = this.props.teams;
        //this.teams = this.datasource;
        console.log("timelineDash teams : ",this.teams);
        let arrayEquipe = [];
        for (let i = 0 ; i < this.teams.length;i++){
            arrayEquipe.push(...this.teams[i].teamPlanningVOS)
        }
        this.equipes = arrayEquipe;
        console.log("timelineDash equipes : ",this.equipes);
        let array = [];
        for(let i =0;i< this.equipes.length;i++){
            console.log(this.equipes[i].employeeVOS);
            array.push(...this.equipes[i].employeeVOS);
        }
        console.log("array =", array);
        this.agents = array;
    }

    onEventRender(args) {
        let categoryColor = args.data.CategoryColor;
        if (!args.element || !categoryColor) {
            return;
        }
        if (this.scheduleOb.currentView === "Agenda") {
            args.element.firstChild.style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }


    resourceHeaderTemplate = (props) => {
        console.log("resourceHeaderTemplate props : ",props);
        return (
        <div className="template-wrap">

                    <div className="resource-image">
                        <Avatar src={"https://mscore.intelcia.com/picture/default/"+props.resourceData.userName}/>
                    </div>
                    <div className="resource-details">
                        <div className="resource-name">{this.getEmployeeName(props)}</div>
                    </div>
            </div>
        );
    }

    getEmployeeName(value) {
        return ((value.resourceData) ?
            value.resourceData[value.resource.textField] :
            value.resourceName);
    }

    onPopupOpen(props) {
        console.log("onpopupopen : ",props);
    }
    render() {
        const { planningsDash } = this.props;

        return <ScheduleComponent
                    width='100%' height='100%'
                    currentView='TimelineWeek'
                    selectedDate={new Date()}
                    eventSettings={{ dataSource: planningsDash }}
                    group={{ resources: ['Teams', 'agents'] }}
                    ref={(schedule) => (this.scheduleOb = schedule)}
                    readonly={true}
                    eventRendered={this.onEventRender.bind(this)}
                    locale= "fr-CH"
                    resourceHeaderTemplate={this.resourceHeaderTemplate}
                    popupOpen={this.onPopupOpen}
                    showQuickInfo={false}
        >
            <ViewsDirective>
                <ViewDirective option='TimelineDay'/>
                <ViewDirective option='Month'/>
            </ViewsDirective>
            <ResourcesDirective>
                <ResourceDirective field='teamsId' title='team' name='Teams' dataSource={this.equipes} textField='teamName' idField='teamsId' >
                </ResourceDirective>
                <ResourceDirective field='OwnerId' title='agent' name='agents' dataSource={this.agents} textField='fullName' idField='userName' groupIDField='teamsId'>
                </ResourceDirective>
            </ResourcesDirective>
            <Inject services={[TimelineViews, TimelineMonth,Month]}/>
        </ScheduleComponent>;
    }
}
const mapStateToProps = state => ({
    planningsDash:state.dashboard.planningsDash,
});

export default connect(mapStateToProps,{getPlanningsDash})(TimelineDash);
