
export default function quotaReducer(state = {
    holidays: [],
    sites: [],
    quotaLoading: false,
    siteLabel: ""
}, {type, payload}) {
    switch (type) {
        case "GET_QUOTA_HOLIDAYS":
            return {...state, holidays: payload};
        case "GET_SITES":
            return {...state, sites: payload};
        case "QUOTA_LOADING":
            return {...state, quotaLoading: payload};
        case "SET_SITE_LABEL":
            return {...state, siteLabel: payload};
        default:
            return state
    }
}
