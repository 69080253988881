
export default function soldeReducer(state = {
    hr: 0,
    estimated: 0,
    percentage: 0,
    recuperation: 0,
    totalDaysOff: 0,
    totalElements: 0,
    soldeEmployee: [],
    loadingEmployeeSolde: false
}, {type, payload}) {
    switch (type) {
        case "GET_SOLDE":
            return {...state,...payload};
        case "GET_TOTAL_DAYSOFF":
            return {...state, totalDaysOff: payload};
        case "LOADING_EMPLOYEE_SOLDE":
            return {...state,loadingEmployeeSolde: payload};
        case "GET_EMPLOYEE_SOLDE":
            return {...state,soldeEmployee: payload};
        case "GET_TOTAL_EMPLOYEE_SOLDE":
            return {...state,totalElements: payload};
        default:
            return state
    }
}
